export const URL_ORIGIN = "https://www.tamilmurasu.com.sg/";
export const OG_SITE_NAME = "Tamil Murasu";
export const ROBOTS =
  "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1";
export const OG_IMAGE =
  "https://static1.tamilmurasu.com.sg/s3fs-public/tm_default_image.png";

export const FB_PAGES = "163335113676864";
export const FB_APP_ID = "276165649676865";

export const IOS_APP_STORE_ID = "6447955016";
export const ITUNES_APP = "app-id=6447955016";
export const APP_NAME = "Tamil Murasu";

export const ANDROID_PACKAGE = "com.sph.tmandroid";
