import {
  removeLeadingSlash,
  removeTrailingSlash,
} from "@helper/removeTrailingLeadingSlash";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import type { TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import fetchCaas from "@src/app/components/helper/fetchCaas";
import fetchTMApi from "@src/app/components/helper/fetchTMApi";
import { UrlMatch } from "@src/app/data";
import {
  ArticlePageQuery,
  ArticlePageQueryByUrl,
} from "@src/app/query/article";
import { getOsBySection } from "@src/app/query/query";
import { MoreArticlesRequestData } from "@src/app/types/MoreArticlesApi";
import { OSResponse, PartialCueArticle } from "@src/app/types/Opensearch";
import getOSProcessedArticleData, {
  type ProcessedArticleData,
} from "@transformer/useOsResponse";

import { isMatchResult } from "../Article/Article.server";

export const fetchPhotoGalleryData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithoutRedirect<ProcessedArticleData[], string>
> => {
  const section = removeLeadingSlash(removeTrailingSlash(urlComponent.path));

  try {
    const SectionList = (await fetchCaas({
      searchQuery: getOsBySection(section, 7),
    })) as OSResponse<PartialCueArticle>;

    const hits = SectionList.payload.hits.total.value;
    if (hits === 0) {
      return { type: ResponseType.CLIENT_ERROR, statusCode: 404 };
    }

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: getOSProcessedArticleData(SectionList),
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchMoreStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  try {
    const response: ProcessedArticleData[] = await fetchTMApi(
      "more-section-stories",
      "POST",
      {
        section: requestData.filterArticlesBasedOn,
        sort: requestData.sort,
        size: 7,
      },
    );

    return response;
  } catch (error) {
    return [];
  }
}

export type PayloadResponseType = {
  article: ProcessedArticleData;
};

export const fetchPhotoGalleryDetailData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithRedirect<PayloadResponseType | null, string>
> => {
  const id = isMatchResult(urlComponent) ? urlComponent.params.id : undefined;

  let articleQuery;
  if (!id) {
    const urlPath = removeTrailingSlash(urlComponent.path);
    articleQuery = ArticlePageQueryByUrl(urlPath);
  } else {
    articleQuery = ArticlePageQuery(id);
  }

  try {
    const data = (await fetchCaas({
      searchQuery: articleQuery,
    })) as OSResponse<PartialCueArticle> | null;

    const articlePayload =
      data?.payload.hits.hits[0]?._source.data.context ?? null;
    if (articlePayload == null || data == null) {
      return {
        type: ResponseType.CLIENT_ERROR,
        statusCode: 404,
        payload: "PhotoGallery not found",
      };
    }
    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: {
        article: getOSProcessedArticleData(data)[0],
      },
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};
