import { ProcessedSubHead } from "@src/app/types/Opensearch";

import Heading from "../../Typography/Heading";
import ApplyParagraphAnnotations from "../ApplyParagraphAnnotations";

type SubHeadProps = {
  element: ProcessedSubHead;
};

export default function SubHead({ element }: SubHeadProps) {
  return (
    <Heading
      className="font-normal text-md text-red-100"
      Element={element.headingTag}
    >
      {ApplyParagraphAnnotations(element.subhead, element.annotations)}
    </Heading>
  );
}
