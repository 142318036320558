import { ResponseType, TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { ReactElement, ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import styles from "./StatusHandler.module.css";

type Response = TRouteWithRedirect<unknown, string>;
type Props = Readonly<{
  response?: Response;
  clientSideError?: true;
}>;

type ErrorMessage = { pageTitle: string; title: string; lead: ReactNode };

const SERVER_ERROR_MESSAGE = {
  pageTitle: "Unexpected Error",
  title: "Oops, something went wrong.",
  lead: "Try refreshing this page or contact us if the problem persists.",
};

const textForStatusCode: Partial<
  Record<
    Response["statusCode"] | Response["type"] | "clientSideError",
    ErrorMessage
  >
> = {
  [ResponseType.CLIENT_ERROR]: {
    pageTitle: "Bad Request",
    title: "We are sorry, the page you are looking for cannot be served.",
    lead: "There is a problem with your request.",
  },
  404: {
    pageTitle: "Page not found",
    title: "We are sorry, the page you are looking for cannot be found.",
    lead: (
      <>
        Head to our{" "}
        <a href="/">
          <u>home page</u>
        </a>
        .
      </>
    ),
  },
  [ResponseType.REDIRECT]: {
    pageTitle: "Redirect",
    title: "You are being redirected.",
    lead: undefined,
  },
  [ResponseType.SERVER_ERROR]: SERVER_ERROR_MESSAGE,
  503: {
    pageTitle: "Service Unavailable",
    title: "Oops, something took too long.",
    lead: "Try refreshing this page after 30 seconds or contact us if the problem persists.",
  },
  clientSideError: {
    pageTitle: "Unexpected Error",
    title: "Oops, something went wrong.",
    lead: "Try refreshing this page, updating your browser or contact us if the problem persists.",
  },
};

export default function ErrorPage({
  response,
  clientSideError,
}: Props): ReactElement {
  const text =
    (clientSideError
      ? textForStatusCode.clientSideError
      : ((response?.statusCode !== undefined &&
          textForStatusCode[response.statusCode]) ??
        (response?.type !== undefined && textForStatusCode[response.type]))) ||
    SERVER_ERROR_MESSAGE;
  const navigate = useNavigate();

  useEffect(() => {
    if (response?.type === ResponseType.REDIRECT) {
      navigate(response.target);
    }
  }, [response, navigate]);

  return (
    <main className={styles.main}>
      <Helmet>
        <title>{`${text.pageTitle} — The Citrus Times`}</title>
      </Helmet>
      <h1>{text.title}</h1>
      <p>
        {response?.type === ResponseType.REDIRECT ? (
          <a href={response.target}>
            <u>Please click here if your browser does not redirect you.</u>
          </a>
        ) : (
          text.lead
        )}
      </p>
    </main>
  );
}
