import DefaultImage from "@assets/tm_default_image.webp";
import { type RawImageField } from "@src/app/types/Opensearch";
import { twMerge } from "tailwind-merge";

import Heading from "../Elements/Typography/Heading";
import Image from "../ResponsiveImage/Image";

export type PhotoGalleryStyleProps = {
  cardWrapper?: string;
  contentWrapper?: string;
  paragraphStyle?: string;
  titleWrapper?: string;
  titleStyle?: string;
  imageWrapperStyle?: string;
  imageStyle?: string;
};

export type PhotoGalleryProps = {
  linkTo: string;
  title: string;
  paragraph?: string;
  fullDate?: string;
  time?: string;
  displayWidth: number;
  displayHeight: number;
  imageField?: RawImageField;
  imageAlt?: string;
  urlPath?: string;
  overrideStyle?: PhotoGalleryStyleProps;
};

export default function PhotoGalleryCard({
  title,
  displayWidth,
  displayHeight,
  imageField,
  imageAlt,
  linkTo,
  overrideStyle,
}: PhotoGalleryProps) {
  const {
    cardWrapper,
    contentWrapper,
    imageWrapperStyle,
    imageStyle,
    titleStyle,
    titleWrapper,
  } = overrideStyle || {};

  return (
    <div className={twMerge("flex flex-col", cardWrapper)}>
      <a href={linkTo}>
        <div
          className={twMerge(
            "relative flex flex-col min-w-0 break-words bg-white bg-clip-border border border-black-100/[.125] rounded",
            imageWrapperStyle,
          )}
        >
          <Image
            displayWidth={displayWidth}
            srcWidth={displayWidth}
            srcHeight={displayHeight}
            src={imageField?.url ?? DefaultImage}
            alt={imageAlt ?? title}
            className={twMerge("w-full", imageStyle)}
          />

          <div
            className={twMerge(
              "flex flex-col absolute top-0 right-0 bottom-0 left-0 p-0 rounded-[calc(.25rem-1px)]",
              contentWrapper,
            )}
          >
            <div
              className={twMerge(
                "px-4 mt-auto opacity-70 bg-neutral-700 p-5 text-white-100",
                titleWrapper,
              )}
            >
              <Heading
                Element="h2"
                className={twMerge(
                  "font-semibold xs:font-medium text-2xl max-md:text-2sm max-md:leading-500",
                  titleStyle,
                )}
              >
                {title}
              </Heading>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}
