export const NEURON_API_URL = import.meta.env.VITE_NEURON_API_URL as string;

export const NEURON_API_TOKEN = import.meta.env.VITE_NEURON_API_TOKEN as string;

export const MODE = import.meta.env.MODE;

export const NEW_RELIC_KEY = import.meta.env.VITE_NEW_RELIC_KEY as string;

export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string | undefined;

export const CAS_API_KEY = import.meta.env.VITE_CDP_API_KEY as string;

export const CAS_ACTIVATION_URL = import.meta.env
  .VITE_CDP_ACTIVATION_URL as string;

export const CAS_UGC_SECTION_BANNER_ID = import.meta.env
  .VITE_CDP_UGC_ID as number;

export const CAS_CDP_HOME_ID = import.meta.env.VITE_CDP_HOME_ID as number;

export const BC_PLAYLIST_ID: string = "1775080379821060729";

export const BC_PORTRAIT_PLAYLIST_ID: string = "1761698626061298217";

export const BC_LANDSCAPE_PLAYLIST_ID: string = "1781434764065900681";

export const BC_PLAYER_ID = import.meta.env.VITE_BC_PLAYER_ID as string;

export const BC_PLAYER_ID_SMALL_WIDGET = import.meta.env
  .VITE_BC_PLAYER_ID_SMALL_WIDGET as string;

export const BC_ACCOUNT_ID = import.meta.env.VITE_BC_ACCOUNT_ID as string;

export const BC_POLICY_KEY = import.meta.env.VITE_BC_POLICY_KEY as string;

export const LIVEBLOG_ID = import.meta.env.VITE_LIVEBLOG_ID as string;

export const HOME_STORY_QUEUE_ID = import.meta.env
  .VITE_HOME_STORY_QUEUE_ID as string;

export const CAS_EI_MODEL_ID = import.meta.env.VITE_CDP_EI_ID as number;

export const GOOGLE_MAPS_API_KEY = import.meta.env
  .VITE_GOOGLE_MAPS_API_KEY as string;

export const EMARSYS_URL = import.meta.env.VITE_EMARSYS_BASE_URL as string;

export const DRUPAL_API_ENDPOINT = import.meta.env
  .VITE_DRUPAL_API_ENDPOINT as string;

export const LIVE_RAMP_PID = import.meta.env.VITE_LIVE_RAMP_PID as string;

export const NEURON_MOST_READ_API_TOKEN = import.meta.env
  .VITE_NEURON_MOST_READ_API_TOKEN as string;

export const NEURON_MOST_READ_BASE_URL = import.meta.env
  .VITE_NEURON_MOST_READ_BASE_URL as string;

export const ENVIRONMENT: string = import.meta.env.VITE_ENVIRONMENT;

export const STORY_QUEUE_ID: string | undefined = import.meta.env
  .VITE_STORY_QUEUE_ID as string | undefined;

export const ADS_DOMAIN: string =
  ENVIRONMENT == "prd"
    ? "https://adtag.sphdigital.com"
    : "https://adtag-stg.sphdigital.com";
