import fetchTMApi from "@helper/fetchTMApi";
import { NewsletterPostResponse } from "@src/app/types/Newsletter";
import type { ReactElement } from "react";
import { FormEvent, useEffect, useState } from "react";
type Props = Readonly<{
  testId?: string;
}>;
export default function NewsletterSignupForm({
  testId = "newsletter-form",
}: Props): ReactElement {
  const [email, setEmail] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  async function handleOnSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    // validate email
    if (
      email &&
      getValidEmailRegex().test(email) // Valid email format
    ) {
      try {
        const res = await fetchTMApi("newsletterSignup", "POST", {
          email,
        });
        const data = (await res) as NewsletterPostResponse;
        if (data.msg) {
          setSuccessMsg(data.msg);
          return;
        }

        if (data.errorMsg) {
          setErrorMsg("");
          return;
        }
      } catch (e: unknown) {
        console.log("failed with error: ", e);
        return;
      }
    } else {
      // Invalid email
      console.log("Invalid email.");
      return;
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  function getValidEmailRegex() {
    return /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  }

  useEffect(() => {
    if (errorMsg.length > 0 || successMsg.length > 0) {
      setTimeout(() => {
        setErrorMsg("");
        setSuccessMsg("");
      }, 3000);
    }
  }, [errorMsg, successMsg]);

  return (
    <>
      {errorMsg ? (
        <div className="w-full py-3 px-5 mb-4 border border-solid border-red-200 rounded text-white-300 bg-pink-100 text-xs font-normal leading-800">
          {errorMsg}
        </div>
      ) : successMsg ? (
        <div className="w-full py-3 px-5 mb-4 border border-solid border-green-200 rounded text-green-300 bg-green-100 text-xs font-normal leading-800">
          {successMsg}
        </div>
      ) : (
        <>
          <div className="text-xs leading-800">
            மின்னஞ்சல் பதிவு செய்வதன் மூலம் செய்திகள், நிகழ்ச்சிகள் மற்றும் பல
            தகவல்களை மின்னஞ்சல் வழி பெறுவீர்கள்!
          </div>
          <form
            onSubmit={(e: FormEvent<HTMLFormElement>) => void handleOnSubmit(e)}
            data-testid={testId}
            className="w-full"
          >
            <div>
              <input
                onChange={handleInputChange}
                type="email"
                className="block w-full h-9 py-1.5 px-3 mt-1 text-xs font-normal leading-800 text-gray-1000 bg-white bg-clip-padding border border-solid border-gray-900 rounded transition ease-in-out duration-150 delay-0 focus:outline-none focus:border-pink-400 focus:shadow-5xl"
                placeholder="மின்னஞ்சல் முகவரியை இங்கு பதிவிடுக!"
                required={true}
                maxLength={254}
                size={60}
              />
            </div>
            <button className="w-full align-middle select-none py-1.5 px-3 text-xs leading-800 rounded transition ease-in-out duration-150 delay-0 border-0 mt-1 mb-2 text-white-100 bg-100 relative">
              அனுப்பு
            </button>
          </form>
          <div className="text-xs font-normal leading-900 text-gray-100">
            பதிவு செய்வதன் மூலம்{" "}
            <a
              href="https://www.sph.com.sg/tnc/privacy"
              target="_blank"
              className="text-red-100 hover:text-red-200 hover:underline"
              rel="noreferrer"
            >
              &apos;தனியுரிமை கொள்கை&apos;
            </a>{" "}
            மற்றும்{" "}
            <a
              href="https://www.sph.com.sg/tnc/website"
              target="_blank"
              className="text-red-100 hover:text-red-200 hover:underline"
              rel="noreferrer"
            >
              &apos;விதிமுறைகள் &amp; நிபந்தனைகள்&apos;
            </a>{" "}
            ஆகியவற்றுக்கு நீங்கள் உடன்படுகிறீர்கள்.
          </div>
        </>
      )}
    </>
  );
}
