import PlayImage from "@assets/video_widget/Play.webp";
import { Image } from "@elements/ResponsiveImage/Image";
import { twMerge } from "tailwind-merge";

export type VideoPosterProps = {
  formattedTime?: string | undefined;
  src: string;
  nowPlaying: boolean;
  videoPosterDimensions?: string;
};

export default function VideoPoster({
  formattedTime,
  src,
  nowPlaying,
  videoPosterDimensions,
}: VideoPosterProps) {
  return (
    <div className={twMerge(videoPosterDimensions || `w-[140px] h-[79px]`)}>
      <div className={`bg-black-100 relative`}>
        {!nowPlaying && (
          <span
            className="text-white-100 bg-black-100 text-xs bg-opacity-60 absolute z-1 right-0 bottom-0 py-0.5 px-xxs"
            data-testid="time"
          >
            <img
              alt="video-play-icon"
              height={24}
              width={24}
              className="inline"
              src={PlayImage}
            />
            {formattedTime}
          </span>
        )}
        <Image
          objectFit="contain"
          src={src}
          className={twMerge(
            videoPosterDimensions || `w-[140px] h-[79px]`,
            nowPlaying && "blur-sm",
          )}
          srcWidth={259}
          srcHeight={130}
          alt="brightcove video"
          dataTestId="image"
        />
        {nowPlaying && (
          <div className="bg-black-300 bg-opacity-[50%] z-3 top-0 absolute h-full w-full text-white"></div>
        )}
        {nowPlaying && (
          <div className="z-3 absolute text-[white] text-base h-fit top-0 r-0 m-[auto] bottom-0 left-0 right-0">
            Now playing
          </div>
        )}
      </div>
    </div>
  );
}
