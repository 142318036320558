import { ProcessedArticleData } from "@transformer/useOsResponse";
import { useEffect } from "react";

export default function useSetFirstRenderedArticleIds(
  articles: ProcessedArticleData[],
  setRenderedArticleIds: React.Dispatch<React.SetStateAction<Set<string>>>,
) {
  useEffect(() => {
    const articleIdsToAdd: string[] = [];

    articles.forEach((article) => {
      articleIdsToAdd.push(article.id);
    });

    setRenderedArticleIds(
      (prevState) => new Set([...prevState, ...articleIdsToAdd]),
    );
  }, [articles, setRenderedArticleIds]);
}
