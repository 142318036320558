import { URL_ORIGIN } from "@elements/MetaTags/MetaTagsConstants";
import { ProcessedArticleData } from "@transformer/useOsResponse";

import { removeLeadingSlash } from "./removeTrailingLeadingSlash";
import { formatDate } from "./useDateFormat";

export function getArticleJSONLD(article: ProcessedArticleData): object {
  const { urlPath, title, imageField, datetime, dateEdited, authors } = article;
  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "NewsArticle",
        headline: title,
        name: title,
        image: {
          "@type": "ImageObject",
          representativeOfPage: "True",
          url: imageField?.url,
        },
        datePublished: formatDate(datetime, "MMM d, yyyy | hh:mm a"),
        dateModified: formatDate(dateEdited, "MMM d, yyyy | hh:mm a"),
        hasPart: {
          "@type": "WebPageElement",
          cssSelector: ".paywall",
        },
        isAccessibleForFree: "True",
        author: {
          "@type": "Organization",
          "@id": "tamilmurasu",
          url: "Tamil Murasu",
          logo: {
            "@type": "ImageObject",
            url: authors?.[0]?.urlPath,
          },
        },
        publisher: {
          "@type": "Organization",
          "@id": "tamilmurasu",
          name: "தமிழ் முரசு",
          url: URL_ORIGIN,
          logo: {
            "@type": "ImageObject",
            url: urlPath,
          },
        },
        mainEntityOfPage: `${URL_ORIGIN}${removeLeadingSlash(urlPath || "")}`,
      },
    ],
  };

  return jsonLd;
}
