import BannerEn from "@assets/translate_banner/Banner_EN.svg";
import BannerTm from "@assets/translate_banner/Banner_TM.svg";
import BannerUnitEn from "@assets/translate_banner/Banner_Unit_EN.svg";
import BannerUnitTm from "@assets/translate_banner/Banner_Unit_TM.svg";
import { Image } from "@elements/ResponsiveImage/Image";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export type TranslateBannerProps = {
  className?: string;
  mobileView?: boolean;
};

export default function TranslateBanner({
  className,
  mobileView = false,
}: TranslateBannerProps) {
  const [imageIndex, setImageIndex] = useState(0);

  let images = [BannerTm, BannerEn];
  if (mobileView) {
    images = [BannerUnitTm, BannerUnitEn];
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [images.length]);

  return (
    <div className={className}>
      <Link
        to="/tamil-translation-service-singapore"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src={images[imageIndex]}
          alt={"Translation Banner"}
          className="w-full !object-contain !aspect-auto"
          srcWidth={100}
          srcHeight={100}
        />
      </Link>
    </div>
  );
}
