/* eslint-disable react-hooks/rules-of-hooks */
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import WrapperBlock from "@container/WrapperBlock";
import {
  CreateLoadMoreHandler,
  type CreateLoadMoreHandlerProps,
} from "@hooks/useLoadMore/CreateLoadMoreHandler";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import {
  CustomContext,
  ResponseType,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import ArticleCard from "@src/app/components/Card/ArticleCard";
import SideBlock from "@src/app/components/Container/SideBlock";
import NdSideBlock from "@src/app/components/Container/SideBlock/NdSideBlock";
import { Imu1CompanionAdsInfiniteScroll } from "@src/app/components/Elements/Advertisement/variant/AdsCompanion";
import { NdHeader } from "@src/app/components/Header/NdHeader";
import { displayAfter, hideAfter } from "@src/app/components/helper/utils";
import LoadMore from "@src/app/hooks/useLoadMore/LoadMore";
import useSetFirstRenderedArticleIds from "@src/app/hooks/useLoadMore/SetFirstRenderedArticleIds";
import { PageAdTargetingTypeEnum } from "@src/app/types/Ads.d";
import { MoreArticlesRequestData } from "@src/app/types/MoreArticlesApi";
import { type ProcessedArticleData } from "@transformer/useOsResponse";
import { Fragment, useState } from "react";

import ErrorPage from "../ErrorPage";
import NotFound from "../NotFound";
import type { KeywordPayloadResponseType } from "./Keyword.server";
import { fetchMoreTagsStoriesData } from "./Keyword.server";
import { KeywordMetaData } from "./KeywordMetaData";

const PAGE_SIZE = 5;
const INITIAL_ARTICLE_COUNT = 5;

export default function KeywordPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<KeywordPayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }

  const keywordArticles = dataLoaderResponse.payload.data;
  const tagAlias = dataLoaderResponse.payload.tagAlias;

  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);

  const showLoadMore: boolean = keywordArticles.length == INITIAL_ARTICLE_COUNT;
  useSetFirstRenderedArticleIds(keywordArticles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length == 0 ? keywordArticles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: tagAlias,
      sort: lastLoadedArticle?.sort,
    };

    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreTagsStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: PAGE_SIZE,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);
    return response;
  };
  let tagName = "";
  for (const tag of keywordArticles[0].tags || []) {
    if (tag.alias?.includes(tagAlias)) {
      if (tag.name !== undefined) {
        tagName = tag.name;
        break;
      }
    }
  }
  let showBreadcrumbs = true;
  let showTitle = true;
  if (tagName === "தேசிய தினம் 2024") {
    showBreadcrumbs = false;
    showTitle = false;
  }
  const pageAdTargetValue = PageAdTargetingTypeEnum.TAG;
  const sectionName = "keywords";

  const { gaData, metaTags } = KeywordMetaData({
    tagName,
    tagAlias,
  });

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      {tagName === "தேசிய தினம் 2024" && <NdHeader />}

      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock
          sectionName={tagName}
          showBreadcrumbs={showBreadcrumbs}
          showTitle={showTitle}
        >
          {[...keywordArticles, ...loadMoreData].map((section, index) => (
            <Fragment key={section.id}>
              <ArticleCard
                displayWidth={260}
                displayHeight={192}
                elements={{ hasDate: true }}
                {...section}
                overrideStyle={{
                  cardWrapper:
                    (displayAfter(index + 1, PAGE_SIZE)
                      ? "border-none"
                      : "flex-col lg:flex-row") +
                    (hideAfter(index, PAGE_SIZE)
                      ? " lg:flex-row flex-row-reverse"
                      : ""),
                  titleStyle: hideAfter(index, PAGE_SIZE)
                    ? "font-semibold text-2sm text-gray-100 hover:text-gray-100 lg:text-2lg lg:text-red-100 lg:hover:text-red-200"
                    : "text-md lg:text-2lg",
                  paragraphStyle: hideAfter(index, PAGE_SIZE)
                    ? "hidden lg:block"
                    : "",
                  imageWrapperStyle: hideAfter(index, PAGE_SIZE)
                    ? "w-[90px] h-[90px] lg:h-auto"
                    : "",
                  imageStyle: hideAfter(index, PAGE_SIZE)
                    ? "h-[90px] lg:h-auto"
                    : "",
                }}
              />
              {displayAfter(index + 1, PAGE_SIZE) && (
                <Imu1CompanionAdsInfiniteScroll
                  sectionName={sectionName}
                  index={(index + 1) / PAGE_SIZE}
                  pageAdTargetType={pageAdTargetValue}
                />
              )}
            </Fragment>
          ))}
          {showLoadMore ? (
            <div className="flex w-full justify-start mb-xl">
              <LoadMore
                rootClassName="my-4 w-full"
                onLoadMore={handleLoadMore}
                hasMore={hasMoreStories}
                loadText="Load More"
                mobileInfiniteScroll={true}
              />
            </div>
          ) : null}
        </MainBlock>
        {tagName === "தேசிய தினம் 2024" ? (
          <NdSideBlock
            sectionName={tagName}
            className="w-full lg:block"
            pageAdTargetValue={pageAdTargetValue}
            hideAdForMobile={true}
          />
        ) : (
          <SideBlock
            sectionName={tagName}
            className="w-full lg:block"
            pageAdTargetValue={pageAdTargetValue}
            hideAdForMobile={true}
          />
        )}
      </WrapperBlock>
    </MainContainer>
  );
}
