import Image from "@components/ResponsiveImage/Image";
import { Link } from "react-router-dom";

import Heading from "../../Elements/Typography/Heading";
import { ZODIACS } from "./constants";

export default function HoroscopeBlock() {
  return (
    <div>
      <Heading Element="h2" className="pb-2.5 text-2.5md font-bold leading-650">
        ராசிபலன்
      </Heading>
      <div className="flex flex-wrap justify-center">
        {ZODIACS.map((zodiac, i: number) => (
          <div
            key={i}
            className={`text-center relative flex-33 max-w-33 lg:flex-16 lg:max-w-16 sm:flex-25 sm:max-w-25 ${(i + 1) % 3 == 0 ? " pr-0 lg:pr-2.5" : "pr-2.5"} pb-2.5 hover:underline`}
          >
            <Link reloadDocument to={`/horoscope/${zodiac.en}`}>
              <div className="w-31 h-31 max-xsm:w-23 max-xsm:h-23 rounded-50 flex bg-gray-100 hover:bg-gradient-to-b hover:from-[#FFC824] hover:to-[#FF1075] p-1.5">
                <div className="h-full w-full p-200 mx-auto items-center bg-gray-100 rounded-50 justify-center overflow-hidden">
                  <Image
                    srcWidth={90}
                    srcHeight={90}
                    src={zodiac.img}
                    alt={zodiac.en}
                    className="inline"
                    objectFit="fill"
                  />
                </div>
              </div>
              <h6 className="text-2sm font-bold leading-450 text-gray-100">
                {zodiac.ta}
              </h6>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
