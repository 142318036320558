import { ReactNode } from "react";

export type WrapperBlockProps = Readonly<{
  children: ReactNode;
  className: string;
}>;

export default function WrapperBlock({
  children,
  className,
}: WrapperBlockProps) {
  return (
    <div data-testid="wrapper-block" className={className}>
      {children}
    </div>
  );
}
