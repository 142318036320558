/* eslint-disable react-hooks/rules-of-hooks */
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import SideBlock from "@container/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import {
  CreateLoadMoreHandler,
  type CreateLoadMoreHandlerProps,
} from "@hooks/useLoadMore/CreateLoadMoreHandler";
import LoadMore from "@hooks/useLoadMore/LoadMore";
import useSetFirstRenderedArticleIds from "@hooks/useLoadMore/SetFirstRenderedArticleIds";
import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import {
  CustomContext,
  ResponseType,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import PhotoGalleryCard from "@src/app/components/Card/PhotoGalleryCard";
import { Imu1CompanionAdsInfiniteScroll } from "@src/app/components/Elements/Advertisement/variant/AdsCompanion";
import Outbrain from "@src/app/components/Elements/OutbrainAds/Outbrain";
import { displayAfter } from "@src/app/components/helper/utils";
import { PageAdTargetingTypeEnum } from "@src/app/types/Ads.d";
import { MoreArticlesRequestData } from "@src/app/types/MoreArticlesApi";
import { type ProcessedArticleData } from "@transformer/useOsResponse";
import { Fragment, useState } from "react";

import ErrorPage from "../ErrorPage";
import NotFound from "../NotFound";
import { fetchMoreStoriesData } from "./PhotoGallery.server";
import { PhotoGalleryMetaData } from "./PhotoGalleryMetaData";

const PAGE_SIZE = 7;
const INITIAL_ARTICLE_COUNT = 7;

export default function PhotoGallery() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<ProcessedArticleData[], string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }
  const sectionArticles = dataLoaderResponse.payload;

  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);

  const showLoadMore: boolean = sectionArticles.length == INITIAL_ARTICLE_COUNT;
  useSetFirstRenderedArticleIds(sectionArticles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length == 0 ? sectionArticles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: sectionArticles[0].sectionName,
      sort: lastLoadedArticle && lastLoadedArticle.sort,
    };

    const createLoadMoreProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      loadMoreData,
      renderedArticleIds,
      lastLoadedArticle,
      pageSize: PAGE_SIZE,
      pageNumber,
    };
    const response = await CreateLoadMoreHandler(createLoadMoreProps);
    return response;
  };

  let sectionName = "";
  const sectionTamilName = "படச் செய்திகள்";
  if (sectionArticles[0].sectionName) {
    sectionName = sectionArticles[0].sectionName;
  }

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;

  const { gaData, metaTags } = PhotoGalleryMetaData({
    sectionName,
    sectionTamilName,
  });

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock sectionName={sectionName}>
          <div className="flex flex-wrap">
            {[...sectionArticles, ...loadMoreData].map((section, index) => (
              <Fragment key={section.id}>
                {displayAfter(index, PAGE_SIZE) && (
                  <>
                    <div className="w-full my-2">
                      <div className="border-t border-t-gray-2000 mt-2"></div>
                      <Outbrain pathName={sectionName} />
                    </div>
                    <Imu1CompanionAdsInfiniteScroll
                      sectionName={sectionName}
                      index={index / PAGE_SIZE}
                      pageAdTargetType={pageAdTargetValue}
                    />
                  </>
                )}
                <PhotoGalleryCard
                  displayWidth={
                    (index % INITIAL_ARTICLE_COUNT) % 3 == 0 ? 748 : 371
                  }
                  displayHeight={
                    (index % INITIAL_ARTICLE_COUNT) % 3 == 0 ? 499 : 247
                  }
                  {...section}
                  overrideStyle={{
                    cardWrapper:
                      (index % INITIAL_ARTICLE_COUNT) % 3 == 0
                        ? "lg:flex-100 py-1"
                        : (index % INITIAL_ARTICLE_COUNT) % 3 == 1
                          ? "lg:flex-50 pr-0.5"
                          : (index % INITIAL_ARTICLE_COUNT) % 3 == 2
                            ? "lg:flex-50 pl-0.5"
                            : "",
                  }}
                />
              </Fragment>
            ))}
            {showLoadMore ? (
              <div className="flex w-full justify-start mb-xl">
                <LoadMore
                  rootClassName="my-4 w-full"
                  onLoadMore={handleLoadMore}
                  hasMore={hasMoreStories}
                  loadText="Load More"
                />
              </div>
            ) : null}
          </div>
        </MainBlock>
        <SideBlock
          sectionName={sectionName}
          className="w-full lg:block"
          pageAdTargetValue={pageAdTargetValue}
        />
      </WrapperBlock>
    </MainContainer>
  );
}
