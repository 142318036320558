/**
 * Company: SPHMedia
 * Description: [Brief description of the component]
 */
import { ReactElement } from "react";

import getCaasSrcs from "./getCaasSrcs";

export type ImageProps = {
  /** the style for the image */
  className?: string;
  /** the url of the image, should be a CaaS image url to support srcset optimization */
  src: string;
  /** img alt attribute, required for accessibility, can be empty string for decorative images */
  alt: string;
  /** the width of the image referenced in src  */
  srcWidth: number;
  /** the height of the image referenced in src  */
  srcHeight: number;
  /** the width the 1x image is supposed to be displayed at, usually the max width that the container can reach, take care beyond 1000 and do not exceed 2000. */
  displayWidth?: number;
  /** img loading for browser native lazy loading */
  loading?: "lazy" | "eager";
  /** dataTestId for unit testing */
  dataTestId?: string;
  /** dataTestId for unit testing */
  objectFit?: "contain" | "cover" | "fill";
};

/**
 * Image
 * This is Image Component
 * @param props
 */
export default function Image({
  className,
  src: srcProp,
  alt,
  srcWidth,
  srcHeight,
  displayWidth,
  loading,
  dataTestId,
  objectFit = "cover",
}: ImageProps): ReactElement {
  const srcSets = displayWidth
    ? getCaasSrcs(srcProp, srcWidth, displayWidth)
    : { src: srcProp };

  return (
    <img
      className={className}
      style={{
        aspectRatio: srcWidth / srcHeight,
        objectFit: objectFit,
      }}
      data-testid={dataTestId}
      alt={alt}
      title={alt}
      loading={loading}
      {...srcSets}
    />
  );
}
