import { getWindowOrigin } from "./utils";

export default async function fetchTMApi<T>(
  endpoint: string,
  method: "GET" | "POST",
  requestData?: unknown,
): Promise<T> {
  const url = `${getWindowOrigin()}/_plat/api/v1/${endpoint}`;
  const options: RequestInit = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    body: method === "POST" ? JSON.stringify(requestData) : undefined,
  };

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const responseData = (await response.json()) as T;
    return responseData;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}
