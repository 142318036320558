import type { ReactElement } from "react";
import { Link } from "react-router-dom";

export function HeaderSubscribe(): ReactElement {
  return (
    <>
      <Link
        to="https://subscribe.sph.com.sg/publications-tm/?utm_campaign=tm_subscription&utm_medium=sph-publication&utm_source=tm&utm_content=subscribebutton-header"
        rel="noreferrer"
        className="w-xxxl h-lg inline-block text-center rounded bg-100 text-white-100 hover:underline my-100"
      >
        சந்தா
      </Link>
    </>
  );
}
