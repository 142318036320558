// import dummyImage from "@assets/images/author.svg";
import DefaultImage from "@assets/tm_default_image.webp";
import { Image } from "@elements/ResponsiveImage/Image";
import Heading from "@elements/Typography/Heading";
import { ProcessedAuthorContent } from "@typings/Opensearch";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export type AuthorCardProps = {
  author: ProcessedAuthorContent;
  displayWidth: number;
  displayHeight: number;
  imageWrapper?: string;
  hasEmail?: boolean;
  hrefTitle?: boolean;
  authorCardWrapper?: string;
};

export default function AuthorCard({
  author,
  displayWidth,
  displayHeight,
  imageWrapper,
  authorCardWrapper,
  hasEmail = true,
}: AuthorCardProps) {
  const { email, name } = author;
  return (
    <div
      className={twMerge(
        "flex gap-4 pb-100 border-b border-pink-300",
        authorCardWrapper,
      )}
    >
      <div
        className={twMerge(
          "w-[150px] xs:max-md:w-[75px] max-[344px]:w-[75px]",
          imageWrapper,
        )}
      >
        <Image
          displayWidth={displayWidth}
          srcWidth={displayWidth}
          srcHeight={displayHeight}
          src={author.authorImage?.url ?? DefaultImage}
          alt={name}
          dataTestId="author"
          className="rounded-[50%]"
        />
      </div>
      <div className="flex flex-col">
        <Heading className="text-2lg font-bold font-primary pb-100">
          {name}
        </Heading>
        {hasEmail && email && (
          <Link
            to={`mailto:${email}`}
            className={twMerge(
              "w-full flex-shrink-0 md:flex-grow-0 sm:w-[260px] hover:text-red-200 text-red-100 hover:underline",
            )}
          >
            <span>{email}</span>
          </Link>
        )}
      </div>
    </div>
  );
}
