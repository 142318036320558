/* eslint-disable @typescript-eslint/no-unsafe-call */
import { isSameOrigin, prefixCheck } from "@components/helper/utils";
import { ChangeEvent, FormEvent, KeyboardEvent, useState } from "react";

type SearchInputProps = {
  expanded: boolean;
  className?: string;
};

export function SearchInput({ expanded, className }: SearchInputProps) {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    // const sanitized = sanitizeSearchKeyword(event.target.value);
    setSearchValue(event.target.value);
  };

  const handleSearch = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (!searchValue.trim()) return;
      if (prefixCheck(searchValue)) return;
      if (!isSameOrigin(window.origin)) return;
      window.location.href = `/search/google?keys=${encodeURIComponent(
        searchValue,
      )}`;
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!searchValue.trim()) return;
    if (prefixCheck(searchValue)) return;
    if (!isSameOrigin(window.origin)) return;
    window.location.href = `/search/google?keys=${encodeURIComponent(
      searchValue,
    )}`;
  };

  return (
    <>
      {expanded ? (
        <div data-testid="search" className={className}>
          <div
            data-testid="searchExpanded"
            className="text-center border-t border-solid border-gray-400 lg:border-none pt-sm lg:pt-0"
          >
            <form onSubmit={handleSubmit} className="flex">
              <input
                type="text"
                placeholder="Search..."
                value={searchValue}
                onChange={handleInputChange}
                onKeyDown={handleSearch}
                className="flex-auto border border-solid border-gray-900 rounded rounded-tr-none rounded-br-none py-1.5 px-3 outline-none focus:border-pink-400 focus:shadow-5xl focus:z-10 text-xs text-gray-1000 font-primary placeholder-gray-1000 placeholder-opacity-100"
              />
              <button
                type="submit"
                aria-label="Submit Search"
                className="text-center rounded bg-100 rounded-tl-none rounded-bl-none text-white py-1.5 px-3 leading-loose"
              >
                தேடல்
              </button>
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
}
