import MmLogo from "@assets/manavar_murasu/mm_logo.svg";
import MmMobileLogo from "@assets/manavar_murasu/mm_logo_mobile.svg";
import type { ReactElement } from "react";

export function HeaderMmLogo(): ReactElement {
  return (
    <>
      <span className="border-l border-solid border-gray-400 mx-sm hidden lg:block"></span>
      <a href="/maanavar-murasu" rel="manavar_murasu" title="Maanavar Murasu">
        {/* Desktop Logo Image */}
        <img
          alt="மாணவர் முரசு"
          src={MmLogo}
          className="hidden lg:block w-40 h-14 mt-3"
        />
        {/* Mobile Logo Image */}
        <img
          alt="மாணவர் முரசு"
          src={MmMobileLogo}
          className="lg:hidden block w-15 h-lg"
        />
      </a>
    </>
  );
}
