import { formatDistanceToNow } from "date-fns";
import { ms } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";

const timeZone = "Asia/Singapore";
export const dateFormatWithTz = "yyyy-MM-dd'T'HH:mm:ssXXX";

export function formatDate(
  dateString: string,
  dateFormat?: string,
  setLocale?: boolean,
): string {
  const format = dateFormat ?? "MMM d, yyyy";
  const date = new Date(dateString);
  if (setLocale) {
    return formatInTimeZone(date, timeZone, format, { locale: ms });
  } else {
    return formatInTimeZone(date, timeZone, format);
  }
}

export function formatTime(dateString: string): string {
  const date = new Date(dateString);
  return formatInTimeZone(date, timeZone, "h:mm a");
}

export function fullDate(dateString: string): string {
  const date = new Date(dateString);
  return formatInTimeZone(date, timeZone, "dd MMM yyyy");
}

export function getDateTime(dateString: string): string {
  const date = new Date(dateString);
  return formatInTimeZone(date, timeZone, "dd MMM yyyy - h:mm aaa");
}

export const getCurrentDate = () => {
  const currentDate = new Date();
  return formatInTimeZone(currentDate, timeZone, "yyyyMMdd");
};

export function formatDateAgo(dateString: string): string {
  return formatDistanceToNow(new Date(dateString), {
    addSuffix: true,
    includeSeconds: true,
  });
}
