import { isProd } from "@helper/utils";
import { type UserInfoType, useUserInfo } from "@hooks/useUserInfo";
import useOKTAUserStore from "@src/app/store/useOKTAUserStore";
import { ReactElement } from "react";
import { Link } from "react-router-dom";

import {
  HEADER_MAIN_MENU,
  HEADER_SOCIAL_MENU,
  HEADER_TOP_MOBILE_MENU,
} from "../HeaderMenuConstants";
import { HeaderMenuList } from "../HeaderMenuList";
import MySphLoginButton from "../TopHeader/MySPHLoginButton";
import UserInfo from "../TopHeader/UserInfo/UserInfo";

export type HamburgerMenuModalProps = {
  expandMenu: boolean;
  onClick: () => void;
};

export function HamburgerMenuModal({
  expandMenu,
}: HamburgerMenuModalProps): ReactElement {
  const { userInfo } = useUserInfo();
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const userDisplayName = OKTAUserInfo?.display_name || OKTAUserInfo?.loginid;
  return (
    <>
      {expandMenu ? (
        <div className="lg:hidden relative font-primary xs:border-t xs:border-solid xs:border-gray-600 lg:border-none overflow-y-scroll bg-white-100 max-h-[90vh]">
          <div className="pb-6 pt-sm px-xs">
            <Link
              reloadDocument
              className="w-full h-xl inline-block text-center rounded-lg bg-100 text-white-100 pt-2 tracking-widest font-bold"
              to="https://subscribe.sph.com.sg/publications-tm/?utm_campaign=tm_subscription&utm_medium=sph-publication&utm_source=tm&utm_content=subscribebutton-header"
            >
              சந்தா
            </Link>
          </div>
          <div className="px-sm">
            {!isProd && (
              <>
                {userDisplayName ? (
                  <UserInfo
                    userInfo={
                      {
                        ...userInfo,
                        lastname: userDisplayName,
                        loginId: OKTAUserInfo.loginid,
                        sub: OKTAUserInfo.reguserstatus,
                      } as UserInfoType
                    }
                  />
                ) : (
                  <MySphLoginButton />
                )}
              </>
            )}
            <HeaderMenuList
              testId="mobile-top-links"
              items={HEADER_TOP_MOBILE_MENU}
              type="top-mobile"
              isHamburger={true}
            />
            <HeaderMenuList
              testId="mobile-main-links"
              items={HEADER_MAIN_MENU}
              type="main"
              isHamburger={true}
            />
          </div>
          <div className="text-center">
            <HeaderMenuList
              testId="mobile-social-links"
              items={HEADER_SOCIAL_MENU}
              type="social"
              isHamburger={true}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}
