import CatfishAd from "@components/Elements/Advertisement/CatfishAd";
import PrestitialAd from "@components/Elements/Advertisement/PrestitialAd";
import { AdsLb1 } from "@elements/Advertisement/variant/AdsLb";
import type { GADataProps } from "@elements/GAData/GAData";
import GAData from "@elements/GAData/GAData";
import MetaTags from "@elements/MetaTags/MetaTags";
import { type MetaTagsProps } from "@elements/MetaTags/MetaTags";
import getPageDetails from "@helper/getPageDetails";
import useGlobalAdSetting from "@src/app/hooks/useGlobalAdSetting";
import useOKTAUserInfo from "@src/app/hooks/useOktaUserInfo";
import useScript from "@src/app/hooks/useScript";
import { PageAdTargetValue } from "@typings/Ads.d";
import { ReactElement, ReactNode } from "react";

import { GoogleAdsSlotFactory } from "../Elements/Advertisement/helpers";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { HEADER_MAIN_MENU } from "../Header/HeaderMenuConstants";
import { HeaderMenuList } from "../Header/HeaderMenuList";
import { ManavarMurasuHeader } from "../Header/ManavarMurasu/ManavarMurasuHeader";

export type Props = Readonly<{
  testId?: string;
  children: ReactNode;
  gaData?: GADataProps;
  metaTagsProps?: MetaTagsProps;
  sectionName?: string;
  hasLb1Ads?: boolean;
  mobwebview?: boolean;
  pageAdTargetValue: PageAdTargetValue;
}>;

/*
 * Container to provide a fixed width and horizontal padding around the content. Typically paired
 * with a Layout component to create reusable templates across all pages. Width of child content as
 * follows with each screen size:

  Viewport width >= 1280: Children component width: 1150px
  Viewport width >= 1024: Children component width: 990px
  Viewport width >= 992: Children component width: 960px
  Viewport width >= 768: Children component width: 720px
  Viewport width >= 576: Children component width: 540px
  All other viewport widths: Children component width: 100% - padding 30px

 * @param {ReactElement} children - Any React Element but likely used to wrap a Tamil Murasu module
 * @param {String} testId - Unique string used to target elements when testing
 * @returns {ReactElement} - A wrapped module with a fixed width that resizes on view port resize
 */

export default function MainContainer({
  children,
  testId = "main-container",
  gaData,
  metaTagsProps,
  sectionName,
  hasLb1Ads = true,
  mobwebview = false,
  pageAdTargetValue,
}: Props): ReactElement {
  const { hasDesktopNav } = getPageDetails(sectionName) || {
    hasDesktopNav: true,
  };

  const { isPrestitialEnabled, isCatfishEnabled } = useGlobalAdSetting();

  useOKTAUserInfo();
  useScript({
    id: "mysph-lightbox-lib",
    src: import.meta.env.VITE_SPH_LIGHTBOX as string,
  });

  if (sectionName === "maanavar-murasu") {
    hasLb1Ads = false;
  }

  return (
    <>
      {!mobwebview && <Header sectionName={sectionName} />}
      {sectionName === "maanavar-murasu" && <ManavarMurasuHeader />}
      <div
        data-testid={testId}
        className="xl:max-w-xl w-full mx-auto xxs:px-sm lg:p-0 max-xsm:px-1"
      >
        {hasDesktopNav && (
          <div className="hidden lg:block sticky top-0 z-50 bg-white-100">
            <HeaderMenuList
              testId="desktop-main-links"
              items={HEADER_MAIN_MENU}
              type="main"
              isHamburger={false}
            />
          </div>
        )}
        {hasLb1Ads && !mobwebview && (
          <AdsLb1
            sectionName={sectionName}
            pageAdTargetType={pageAdTargetValue}
          />
        )}
        <PrestitialAd
          slot={GoogleAdsSlotFactory.prestitial(sectionName)}
          isPrestitialEnabled={isPrestitialEnabled}
        />
        {gaData && <GAData {...gaData} />}
        {metaTagsProps && <MetaTags {...metaTagsProps} />}
        {children}
      </div>
      {!mobwebview && <Footer />}
      <CatfishAd
        slot={GoogleAdsSlotFactory.catfish(sectionName)}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
