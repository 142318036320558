import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { capitalize } from "@helper/stringTransform";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function VideoMetaData(section: string): PageMetaDataValues {
  const gaData: GADataProps = {
    level2: section,
    title: `${capitalize(section)}_Index`,
    keyword: section,
    contentcat: 1,
    contenttype: 1,
  };

  const metaTags: MetaTagsProps = {
    title:
      "காணொளிகள் | Today's Latest News in Tamil - Tamilmurasu, தமிழ் நியூஸ், அண்மையச் செய்திகள், தமிழ் முரசு",
    description:
      "காணொளிகள் | அண்மையச் செய்திகள் - Get Latest News in Singapore, Tamilnadu, India, World & Sports News in Tamil. சிங்கப்பூர், தமிழ்நாடு, இந்தியா, உலக மற்றும் விளையாட்டு அண்மைய செய்திகளில் தமிழில் சமீபத்தியச் செய்திகளைப் பெறுங்கள்.",
    slug: section,
  };

  return {
    gaData,
    metaTags,
  };
}
