import { CompanionAdUnitProps } from "@typings/Ads.d";
import cx from "classnames";
import { ReactElement } from "react";

import styles from "./Advertisement.module.css";
import { CompanionAdUnit } from "./CompanionAdUnit";
import { useAdvertLoaded } from "./helpers";

export type CompanionAdvertisementProps = {
  rootClassName?: string;
  companionAdUnitProps: CompanionAdUnitProps;
};

// For now but must be move to tailwind custom class later rather than inline style like this.
export const stripeAdsBackground = {
  backgroundImage:
    "linear-gradient(135deg, #f0f0f0 8.33%, #ffffff 8.33%, #ffffff 50%, #f0f0f0 50%, #f0f0f0 58.33%, #ffffff 58.33%, #ffffff 100%)",
  backgroundSize: "8.49px 8.49px",
};

/**
 * This component renders an ad.
 * @param rootClassName - The root class name.
 * @param type - The type of the ad.
 * @param adUnitProps - The props for the ad.
 * @see https://www.imsph.sg/advertising-solutions/digital/imu/
 * @returns
 */
export default function CompanionAdvertisement({
  rootClassName = "",
  companionAdUnitProps,
}: CompanionAdvertisementProps): ReactElement {
  const advertLoaded = useAdvertLoaded(
    companionAdUnitProps.type,
    companionAdUnitProps.id,
  );

  return (
    <div
      className={cx(!advertLoaded && styles.invisible, rootClassName, "py-sm")}
      style={{
        minHeight: "50px",
      }}
      data-testid="advertisement-element"
    >
      <CompanionAdUnit {...companionAdUnitProps} />
    </div>
  );
}
