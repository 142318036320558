import scrollToTopIcon from "@assets/ScrollToTop.webp";
import MainContainer from "@container/MainContainer";
import { AdsImu2 } from "@elements/Advertisement/variant/AdsImu";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import VideoPlayer from "@elements/Player/VideoPlayer";
import Heading from "@elements/Typography/Heading";
import useSwrApi from "@hooks/useSwrApi";
import MainBlock from "@src/app/components/Container/MainBlock";
import WrapperBlock from "@src/app/components/Container/WrapperBlock";
import ShareSocial, {
  type SocialVariant,
} from "@src/app/components/Elements/ShareSocial/ShareSocial";
import fetchTMApi from "@src/app/components/helper/fetchTMApi";
import { isArrayWithElements } from "@src/app/components/helper/utils";
import LoadMore from "@src/app/components/Modules/Video/LoadMore/LoadMore";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import VideoMetaData from "./VideoMetaData";
const socialIconList = ["whatsapp", "facebook", "x", "copylink"];

export default function Video() {
  const { data } = useSwrApi<LatestVideoResponse[]>("latest-videos");
  const { level1: id } = useParams();

  const [offset, setOffset] = useState(10);
  const [playlistMode, setPlaylistMode] = useState(false);
  const [playlistVideos, setPlaylistVideos] = useState<LatestVideoResponse[]>();
  const [videos, setVideos] = useState<LatestVideoResponse[]>();
  const [playing, setPlaying] = useState<LatestVideoResponse | undefined>();

  useEffect(() => {
    const fetchData = async () => {
      if (!data || data.length === 0) return;
      let nowPlaying = false;
      try {
        let videos: LatestVideoResponse[];
        if (id) {
          videos = await fetchTMApi<LatestVideoResponse[]>(
            `latest-videos/${id}`,
            "GET",
          );
        } else {
          videos = await fetchTMApi<LatestVideoResponse[]>(
            "latest-videos-playlist",
            "GET",
          );
          setPlaylistMode(true);
          setPlaylistVideos(videos);
        }

        setPlaying(videos[0]);
        nowPlaying = id ? false : true;
      } catch (error) {
        nowPlaying = true;
        const playlist = await fetchTMApi<LatestVideoResponse[]>(
          "latest-videos-playlist",
          "GET",
        );
        setPlaylistVideos(playlist);
        setPlaying(playlist[0]);
      }

      const updatedVideos = data.map((video, i) =>
        video.id === playing?.id
          ? { ...video, nowPlaying: nowPlaying ? true : false }
          : { ...video, nowPlaying: i === 0 ? true : false },
      );
      setVideos(updatedVideos);
    };
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const containerRef = useRef<HTMLDivElement>(null);

  const handleLoadMoreVideo = async () => {
    const moreVideos = await fetchTMApi<LatestVideoResponse[]>(
      `latest-videos?offset=${offset}`,
      "GET",
    );
    setVideos((prevVideos) => [...(prevVideos || []), ...moreVideos]);
    setOffset((prevSize) => prevSize + 10);

    // This avoids GTM push.
    return 0;
  };

  const handleNowPlaying = useMemo(
    () => (id: string) => {
      setVideos(
        (prevValue) =>
          prevValue &&
          prevValue.map((video) => ({
            ...video,
            nowPlaying: video.id === id,
          })),
      );
    },
    [setVideos],
  );

  const handleOnClickThumbnail = useCallback(
    (props: LatestVideoResponse) => {
      // eslint-disable-next-line react/prop-types
      const { id } = props;
      handleNowPlaying(id);
      setPlaying(props);
      containerRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setPlaylistMode(false);
    },

    [handleNowPlaying, setPlaying],
  );

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;

  const sectionName = "videos";
  const { gaData, metaTags } = VideoMetaData(sectionName);

  const handleOnEndedVideo = () => {
    if (!playlistMode) return;
    if (!playlistVideos) return;
    let index: number = playlistVideos.findIndex(
      (video) => video.id === playing?.id,
    );
    if (index++ === playlistVideos.length - 1) {
      setPlaying(playlistVideos[0]);
    } else {
      setPlaying(playlistVideos[index++]);
    }
  };
  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock showBreadcrumbs={false} showTitle={false}>
          <div className="flex flex-col lg:flex-row">
            <div className="flex lg:px-sm flex-col w-full">
              {playing?.id && (
                <VideoPlayer
                  playing={playing}
                  forwadedRef={containerRef}
                  onEnded={handleOnEndedVideo}
                  nextPlaylist={handleOnEndedVideo}
                  headerType="h1"
                />
              )}
            </div>
          </div>
        </MainBlock>

        {/* Sidebar */}
        <div className="lg:w-4/12 pl-xs pr-xxs min-h-[800px] overflow-hidden">
          {playing?.id && (
            <div className="flex flex-col lg:hidden">
              <Heading
                Element="h3"
                className="font-primary font-medium text-3md leading-900 text-black-300 mb-5"
              >
                {playing.name}
              </Heading>

              <div className="mb-[20px]">{playing.description}</div>
              <div className="flex items-center justify-between mb-[20px]">
                <div>
                  <span className="text-xxs text-semimedium lg:text-[16px] text-grey-900">
                    {playing.topStoryDate}
                  </span>
                  <br />
                  <span className="text-xxs text-semimedium lg:text-[14px] text-grey-700">
                    {" "}
                    {playing.formattedTime} mins
                  </span>
                </div>
                <div className="flex gap-5">
                  {socialIconList.map((variant, index) => (
                    <ShareSocial
                      variant={variant as SocialVariant}
                      pagePath={`/videos/` + playing.id}
                      key={index}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
          <div
            id="scroll-more-videos"
            data-testid="side-block"
            className="w-full h-[800px] overflow-scroll overflow-x-hidden"
          >
            <div className="w-full pt-sm">
              <span className="font-primary font-bold lg:text-2md leading-7 text-grey-400">
                மேலும் காணொளிகள்
              </span>
              <div className="w-full">
                {videos &&
                  isArrayWithElements(videos) &&
                  videos.map((video, index) => (
                    <>
                      <ThumbnailCard<LatestVideoResponse>
                        key={index}
                        handleOnClickThumbnail={handleOnClickThumbnail}
                        variant="video"
                        {...video}
                      />
                      {index == 1 && (
                        <AdsImu2
                          sectionName={sectionName}
                          rootClassName="py-md"
                          pageAdTargetType={pageAdTargetValue}
                        />
                      )}
                    </>
                  ))}
                <LoadMore onLoadMore={handleLoadMoreVideo} />
              </div>
            </div>
          </div>
        </div>
        <button
          className="sticky lg:hidden bottom-[1.5%] left-[100%] cursor-pointer"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <img
            src={scrollToTopIcon}
            alt={"scroll-top"}
            width={44}
            height={44}
          />
        </button>
      </WrapperBlock>
    </MainContainer>
  );
}
