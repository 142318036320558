import List, { ListElements } from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import { ProcessedTextElement } from "@typings/Opensearch";
import { twMerge } from "tailwind-merge";

import ApplyParagraphAnnotations from "../ApplyParagraphAnnotations";

type ParagraphListProps = {
  listStyle: string;
  listType: ListElements;
  listItems: ProcessedTextElement[];
};

export default function ParagraphList({
  listStyle,
  listType,
  listItems,
}: ParagraphListProps) {
  return (
    <List
      className={twMerge(
        "font-primary text-grey-100 text-3sm mb-4 font-semibold list-inside ps-2md leading-430",
        listStyle,
      )}
      Element={listType}
    >
      {listItems.map((item, idx) => (
        <ListItem testId={`item-${idx}`} key={idx} className="pl-10">
          <>{ApplyParagraphAnnotations(item.value, item.annotations)}</>
        </ListItem>
      ))}
    </List>
  );
}
