import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { removeLeadingSlash } from "@helper/removeTrailingLeadingSlash";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export type AuthorMetaDataProps = {
  authorName: string | undefined;
  authorAlias: string;
};

export function AuthorMetaData({
  authorName,
  authorAlias,
}: AuthorMetaDataProps): PageMetaDataValues {
  const metaTitle = `${authorName} - Tamil Murasu`;
  const metaDescription = `${authorName} | Tamil Murasu - Get Latest Singapore, Tamilnadu, India, World & Sports News in Tamil. சிங்கப்பூரின் ஒரே தமிழ் மொழி செய்தித்தாள். சிங்கப்பூர் செய்திகள், இந்தியா செய்திகள், தமிழ்நாடு செய்திகள், விளையாட்டு செய்திகள்.`;

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    slug: removeLeadingSlash(authorAlias),
    description: metaDescription,
  };

  const gaData: GADataProps = {
    chapter1: authorName,
    level2: "authors",
    title: `${authorName}_Index`,
    contentcat: 1,
    contenttype: undefined,
  };

  return {
    gaData,
    metaTags,
  };
}
