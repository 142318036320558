import sarchLogo from "@assets/search.svg";

type SearchButtonProps = {
  onClick: () => void;
};

export function SearchButton({ onClick }: SearchButtonProps) {
  return (
    <>
      <button
        data-testid="searchButton"
        onClick={onClick}
        className="focus:shadow-5xl rounded pr-3 lg:pl-3 pb-1"
      >
        <img
          src={sarchLogo}
          className="rotate-90"
          alt="search-logo"
          width="30"
          height="30"
        />
      </button>
    </>
  );
}
