export type AudioPlayerProps = {
  src: string;
  height?: string;
  title?: string;
};

export default function Audio({
  src,
  title = "#NoTapis",
  height = "200",
}: AudioPlayerProps) {
  return (
    <iframe
      data-testid="podcast-iframe"
      src={src}
      width="100%"
      height={height}
      title={title}
      className="my-2"
    ></iframe>
  );
}
