import MainContainer from "@container/MainContainer";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import {
  CustomContext,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import { lazy } from "react";
import { useState } from "react";

import { PayloadResponseType } from "./Article.server";
const ArticleContentCard = lazy(() => import("./ArticleContentCard"));
const ArticleInfiniteScroll = lazy(() => import("./ArticleInfiniteScroll"));
import ArticleMetaData from "./ArticleMetaData";

export default function ArticlePage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const [currentArticle, setCurrentArticle] = useState<ProcessedArticleData>();

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message={dataLoaderResponse.payload ?? ""} />;
  }

  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;
  const mainArticle = dataLoaderResponse.payload.article;
  const sectionName = mainArticle.sectionName;
  const relatedStories = dataLoaderResponse.payload.relatedStories;

  const handleNewArticle = (newArticles: ProcessedArticleData) => {
    setCurrentArticle(newArticles);
  };

  const { gaData, metaTags } = ArticleMetaData({
    article: currentArticle || mainArticle,
    isMainArticle: currentArticle ? false : true,
  });

  return (
    <MainContainer
      metaTagsProps={metaTags}
      gaData={gaData}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <ArticleContentCard
        mainArticle={mainArticle}
        relatedStories={relatedStories}
        pageAdTargetValue={pageAdTargetValue}
        pageNumber={0}
        isInfiniteScrollArticle={false}
      />

      <ArticleInfiniteScroll
        mainArticle={mainArticle}
        pageAdTargetValue={pageAdTargetValue}
        onNewArticle={handleNewArticle}
      />
    </MainContainer>
  );
}
