import type { ReactElement } from "react";

import useRemoveOverlay from "../hooks/useRemoveOverlay";

type Props = Readonly<{
  message?: string;
}>;

const DEFAULT_MESSAGE = "Server Error!";

export default function ErrorPage({
  message = DEFAULT_MESSAGE,
}: Props): ReactElement {
  useRemoveOverlay();
  return (
    <div>
      <h1>Something went wrong :(</h1>
      <p>{message}</p>
    </div>
  );
}
