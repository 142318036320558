/* eslint-disable react-hooks/rules-of-hooks */
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import SideBlock from "@container/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import {
  CustomContext,
  ResponseType,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { PageAdTargetingTypeEnum } from "@src/app/types/Ads.d";

// import { PhotoGalleryMetaData } from "./PhotoGalleryMetaData";
import ArticleMetaData from "../Article/ArticleMetaData";
import ErrorPage from "../ErrorPage";
import NotFound from "../NotFound";
import { PayloadResponseType } from "./PhotoGallery.server";
import PhotoGalleryContentCard from "./PhotoGalleryContentCard";

export default function PhotoGalleryDetailPage() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message="Something went wrong" />;
  }
  const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;
  const mainArticle = dataLoaderResponse.payload.article;
  // const [currentArticle, setCurrentArticle] = useState<ProcessedArticleData>();
  const title = mainArticle.title;
  const sectionName = mainArticle.sectionName ?? `"Photo Gallery"`;
  const { gaData, metaTags } = ArticleMetaData({
    article: mainArticle,
    isMainArticle: true,
  });

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock sectionName={sectionName} pageName={title} showTitle={false}>
          <PhotoGalleryContentCard
            mainArticle={mainArticle}
            pageAdTargetValue={pageAdTargetValue}
            pageNumber={0}
          />
        </MainBlock>

        <SideBlock
          sectionName={sectionName}
          pageAdTargetValue={pageAdTargetValue}
        />
      </WrapperBlock>
    </MainContainer>
  );
}
