import MmBannerLogo from "@assets/manavar_murasu/mm_quiz_banner_logo.webp";
import MmBannerMobileLogo from "@assets/manavar_murasu/mm_quiz_banner_logo_mobile.webp";
import type { ReactElement } from "react";

export function ManavarMurasuHeader(): ReactElement {
  return (
    <div className="w-full overflow-x-auto whitespace-nowrap">
      <div className="hidden lg:block shadow-3xl leading-100 relative">
        &nbsp;
      </div>
      <div className="bg-orange-200 text-center h-100 lg:h-60 mb-2.5 lg:mb-5 font-primary">
        <img
          className="hidden lg:inline-block w-auto left-lg relative"
          alt="மாணவர் முரசு"
          src={MmBannerLogo}
        />
        <img
          className="lg:hidden inline-block h-auto -m-xxl"
          alt="மாணவர் முரசு"
          src={MmBannerMobileLogo}
        />
        <div className="lg:inline-block align-middle mt-4 lg:mt-xl lg:text-left px-sm md:px-0 lg:right-4xl lg:relative">
          <div className="hidden lg:inline-block text-pink-100 text-xxl font-bold drop-shadow-3xl">
            மாணவர் முரசு புதிர்
          </div>
          <div className="text-md font-normal text-wrap text-center text-pink-200 pb-4 xs:pb-2 lg:text-left">
            கேளிக்கையான புதிர்கள் மூலம் தமிழ்
            <br className="xs:hidden lg:block" /> கற்றுக்கொள்ளுங்கள்
          </div>
          <div className="bg-white-100 rounded-100 w-full md:w-6/12 lg:w-68 text-center h-10 p-2 m-auto lg:ml-0">
            <a
              className="text-pink-200 font-semibold no-underline"
              target="blank"
              href="/students"
            >
              புதிர்களைத் தொடங்குங்கள்
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
