import Advertisement from "@components/Elements/Advertisement/Advertisement";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetValue,
} from "@typings/Ads.d";

import { pubPageAdTargeting } from "../AdsConstants";
/**
 * TM LB1 Ad
 * Description: The adsLb1 component for rendering ads.
 * @name - name of section or page
 * @index - use when lb1 is rendered more than 1
 */
export function AdsLb1({
  sectionName,
  index,
  pageAdTargetType,
}: {
  sectionName?: string;
  index?: number;
  pageAdTargetType: PageAdTargetValue;
}) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;

  if (sectionName === "/") {
    sectionName = "Home";
  }
  return (
    <div className="flex flex-col xl:flex-row items-center justify-center max-w-full mb-4 min-h-[80px] lg:min-h-[120px]">
      <Advertisement
        adUnitProps={{
          type: AdvertisementTypeEnum.LB1,
          slot: GoogleAdsSlotFactory.lb1(sectionName),
          id: index ? `dfp-ad-lb1-${index}` : "dfp-ad-lb1",
          rootClassName:
            "flex items-center [&_iframe]:max-w-screens-md sm:max-w-full",
          pubAdTargeting: [PubPageAdTarget],
        }}
      />
    </div>
  );
}
