import Heading from "@elements/Typography/Heading";
import { GoldDrupalResponse } from "@src/api/endpoint-handlers/getGoldPrice";
import useSwrApi from "@src/app/hooks/useSwrApi";
import Gold22K1GImage from "@src/app/web2-assets/gold/22K1G.webp";
import Gold22K8GImage from "@src/app/web2-assets/gold/22K8G.webp";
import Gold24K1GImage from "@src/app/web2-assets/gold/24K1G.webp";
import Gold24K8GImage from "@src/app/web2-assets/gold/24K8G.webp";

import to2dp from "../../helper/math/to2dp";
import { formatTime, fullDate } from "../../helper/useDateFormat";
import { CurrencyCard } from "./CurrencyCard";

export const GoldSection = () => {
  const { data: drupalResponse } = useSwrApi<GoldDrupalResponse>("gold-prices");
  const data = drupalResponse?.result;
  const date = drupalResponse?.date;
  const hasData = data?.today_gold_rate && data.yesterday_gold_rate;
  const today1g22KGoldPrice = Number(data?.today_gold_rate?.["1g_22k"] || "0");
  const today8g22KGoldPrice = Number(data?.today_gold_rate?.["8g_22K"] || "0");
  const today1g24KGoldPrice = Number(data?.today_gold_rate?.["1g_24K"] || "0");
  const today8g24KGoldPrice = Number(data?.today_gold_rate?.["8g_24K"] || "0");

  const previous1g22KGoldPrice = Number(
    data?.yesterday_gold_rate?.["1g_22k"] || "0",
  );
  const previous8g22KGoldPrice = Number(
    data?.yesterday_gold_rate?.["8g_22K"] || "0",
  );
  const previous1g24KGoldPrice = Number(
    data?.yesterday_gold_rate?.["1g_24K"] || "0",
  );
  const previous8g24KGoldPrice = Number(
    data?.yesterday_gold_rate?.["8g_24K"] || "0",
  );

  const delta1g22kGold =
    previous1g22KGoldPrice > 0
      ? to2dp(
          ((today1g22KGoldPrice - previous1g22KGoldPrice) /
            previous1g22KGoldPrice) *
            100,
        )
      : "0";
  const delta8g22KGold =
    previous8g22KGoldPrice > 0
      ? to2dp(
          ((today8g22KGoldPrice - previous8g22KGoldPrice) /
            previous8g22KGoldPrice) *
            100,
        )
      : "0";
  const delta1g24KGold =
    previous8g22KGoldPrice > 0
      ? to2dp(
          ((today1g24KGoldPrice - previous1g24KGoldPrice) /
            previous1g24KGoldPrice) *
            100,
        )
      : "0";
  const delta8g24KGold = previous8g24KGoldPrice
    ? to2dp(
        ((today8g24KGoldPrice - previous8g24KGoldPrice) /
          previous8g24KGoldPrice) *
          100,
      )
    : "0";

  return (
    <div>
      <Heading
        Element="h2"
        className="text-gray-100 text-2.5md lg:text-md font-bold mt-5 mb-5"
      >
        தங்க விலை
      </Heading>
      {hasData && (
        <div className="flex flex-wrap">
          <CurrencyCard
            symbol={"SGD"}
            title="22K-916 1 கிராமுக்கு"
            imgUrl={Gold22K1GImage}
            priceChange={delta1g22kGold}
            currentPrice={today1g22KGoldPrice}
          />
          <CurrencyCard
            symbol={"SGD"}
            title="22K-916 8 கிராமுக்கு"
            imgUrl={Gold22K8GImage}
            priceChange={delta8g22KGold}
            currentPrice={today8g22KGoldPrice}
          />
          <CurrencyCard
            symbol={"SGD"}
            title="24K-999 1 கிராமுக்கு"
            imgUrl={Gold24K1GImage}
            priceChange={delta1g24KGold}
            currentPrice={today1g24KGoldPrice}
          />
          <CurrencyCard
            symbol={"SGD"}
            title="24K-999 8 கிராமுக்கு"
            imgUrl={Gold24K8GImage}
            priceChange={delta8g24KGold}
            currentPrice={today8g24KGoldPrice}
          />
        </div>
      )}
      {date && (
        <div className="font-primary font-medium text-gray-800 text-xs mt-[30px]">
          Updated on {fullDate(date)} - {formatTime(date)}
        </div>
      )}
    </div>
  );
};
