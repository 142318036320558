import loadingGif from "@assets/loader.gif";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getErrorMessage } from "@helper/getErrorMessage";
import cx from "classnames";
import { useCallback, useEffect, useState } from "react";

import { getInitialValue } from "./IncreasePagination";

interface LoadMoreProps {
  rootClassName?: string;
  onLoadMore: () => Promise<number>;
  hasMore?: boolean;
  loadText?: string;
  mobileInfiniteScroll?: boolean;
}

/**
 *
 * @param onLoadMore the action will be performed when user click the load more button, from the design point of view
 * the load more component should not "understand" or "care" about the action content
 * @param hasMore this boolean indicator from parent logic to tell the component to hide itself or not
 * @returns
 */
export default function LoadMore({
  rootClassName = "",
  onLoadMore,
  hasMore = true,
  loadText = "More Stories",
  mobileInfiniteScroll = false,
}: LoadMoreProps): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [initialScroll, setInitialScroll] = useState(true);
  const [error, setError] = useState<string>("");
  const [scrollLoadingInProgress, setScrollLoadingInProgress] = useState(false);
  const sendDataToGTM = useGTMDispatch();
  const dataToGTMOnInfiniteScroll = useCallback(
    (eventLabelVal: string, landingPageVal: string) => {
      sendDataToGTM({
        event: "infinite_scroll",
        eventCategory: "Infinite Scroll",
        eventAction: "1",
        eventLabel: eventLabelVal,
        landingPage: landingPageVal,
      });
    },
    [sendDataToGTM],
  );

  const handleLoadMore = useCallback(() => {
    setIsLoading(true);

    Promise.resolve(onLoadMore())
      .then((response) => {
        if (response > 0 && typeof window !== "undefined") {
          const eventLabelVal = window.location.href;
          const landingPageVal =
            window.location.origin + window.location.pathname;
          dataToGTMOnInfiniteScroll(eventLabelVal, landingPageVal);
        }
      })
      .catch((err: unknown) => {
        setError(getErrorMessage(err));
      })
      .finally(() => {
        setIsLoading(false);
        setScrollLoadingInProgress(false);
      });
  }, [onLoadMore, dataToGTMOnInfiniteScroll]);

  useEffect(() => {
    function handleScroll() {
      const isMobileView = window.innerWidth < 768;
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;

      const initialValue = getInitialValue(initialScroll, window.innerWidth);
      let triggerPoint = scrollHeight - initialValue;
      if (isMobileView) {
        triggerPoint = initialScroll ? 1700 : scrollHeight - initialValue;
      }

      if (
        scrollTop + clientHeight >= triggerPoint &&
        !scrollLoadingInProgress &&
        hasMore
      ) {
        if (!mobileInfiniteScroll && !initialScroll) {
          return;
        }
        setScrollLoadingInProgress(true);
        handleLoadMore();
        setInitialScroll(false);
      }
    }

    //Remove the butotn load more for mobile for now
    // if (window.innerWidth >= 768) {
    //   window.addEventListener("scroll", handleScroll);
    // }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [
    scrollLoadingInProgress,
    hasMore,
    handleLoadMore,
    initialScroll,
    mobileInfiniteScroll,
  ]);

  const renderButtonText = () => {
    if (error) {
      return error;
    }

    return loadText;
  };

  return (
    <>
      {isLoading ? (
        <LoadingGif />
      ) : (
        <div
          className={cx(
            "col-span-full cursor-pointer text-center md:hidden",
            {
              hidden: !hasMore,
            },
            rootClassName,
          )}
          onClickCapture={handleLoadMore}
        >
          <button className="uppercase bg-pink-500 px-md py-xs rounded text-white-100 text-base font-semibold">
            {renderButtonText()}
          </button>
        </div>
      )}
    </>
  );
}

export function LoadingGif() {
  return (
    <div data-testid="loading-gif" className="mx-auto size-10">
      <img src={loadingGif} alt="Loading..." className="rounded" />
    </div>
  );
}
