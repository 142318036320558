import downArrow from "@assets/actions/down-arrow.svg";
import DefaultImage from "@assets/tm_default_image.webp";
import { isArrayWithElements } from "@components/helper/utils";
import { Image } from "@elements/ResponsiveImage/Image";
import ShareSocialButton from "@elements/ShareSocial/ShareSocialButton";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import { getDateTime } from "@helper/useDateFormat";
import ArticleParagraph from "@src/app/components/Elements/ArticleParagraph/ArticleParagraph";
import GetReadingTime from "@src/app/components/Elements/ArticleParagraph/GetReadingTime";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetValue } from "@typings/Ads.d";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
const socialIconList = ["whatsapp", "facebook", "x", "copylink"];

export type PhotoGalleryContentCardProps = {
  mainArticle: ProcessedArticleData;
  pageAdTargetValue: PageAdTargetValue;
  pageNumber: number;
};

export default function PhotoGalleryContentCard({
  mainArticle,
  pageNumber,
}: PhotoGalleryContentCardProps) {
  const {
    title,
    urlPath,
    sectionName,
    dateCreated,
    dateEdited,
    articleBody,
    imageGallery,
  } = mainArticle;

  const pageUrl = `${urlPath}`;

  // Get page reading time.
  const readingTime = GetReadingTime(articleBody, urlPath);

  const [isDateOpen, setDateIsOpen] = useState<boolean>(false);
  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setDateIsOpen((prev) => !prev);
  };

  return (
    <article>
      <Heading className="text-3md lg:text-lg font-bold font-primary">
        {title}
      </Heading>

      <div className="w-full inline-block py-xm">
        <div className="inline-block">
          <button
            onClick={toggleDropdown}
            className="dropdown-button py-2 flex"
          >
            <Date className="mr-2 text-gray-100">
              {getDateTime(dateCreated)}
            </Date>
            {dateEdited && (
              <img
                src={downArrow}
                alt={"dropdown-arrow"}
                width={10}
                height={10}
                className={twMerge("mt-2", isDateOpen && "-scale-y-100")}
              />
            )}
          </button>
          {isDateOpen && dateEdited && (
            <>
              <div className="mb-2 font-primary font-normal text-gray-800 text-xs lg:flex">
                <span className="mr-1">மாற்றம் செய்யப்பட்ட நாள்:</span>
                <Date className="mt-2 lg:mt-0">{getDateTime(dateEdited)}</Date>
              </div>
            </>
          )}

          <div className="font-primary font-medium text-gray-800 text-xs">
            {readingTime} mins read
          </div>
        </div>

        <div className="float-right">
          <ShareSocialButton
            socialIconList={socialIconList}
            pagePath={pageUrl}
            mbClassName="absolute right-0 bg-white-100 z-10 rounded-3xl mr-sm"
            deskClassName="mb-5"
          />
        </div>
      </div>

      {isArrayWithElements(imageGallery) && (
        <div className="image-gallery">
          {imageGallery.map((galleryItem) => (
            <div key={galleryItem.id} className="mb-4">
              {/* Image Component */}
              <Image
                displayWidth={800} // or use desired width
                srcWidth={800}
                srcHeight={450} // or use desired height
                src={galleryItem.image?.url ?? DefaultImage}
                alt={galleryItem.caption || title}
              />

              {/* Caption and Credit */}
              <div className="mt-2">
                {galleryItem.caption && (
                  <p className="text-sm">{galleryItem.caption}</p>
                )}
                {galleryItem.credit && (
                  <p className="text-xs">{galleryItem.credit}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {isArrayWithElements(articleBody) && (
        <ArticleParagraph
          articleBody={articleBody}
          sectionName={sectionName}
          pageUrl={pageUrl}
          relatedStories={[]}
          pageNumber={pageNumber}
        />
      )}
    </article>
  );
}
