import { useEffect, useState } from "react";

type LoadScriptArguments = {
  /**
   * The script source, e.g. //www.google.com/recaptcha/api.js
   * Will be loaded via `<script src={src} />`
   */
  src: string;

  /**
   * The script id, e.g. recaptcha, google-analytics, etc.
   * This will be used to check if the script is already loaded, so make sure it's unique.
   * Will be loaded via `<script id={id} />`
   */
  id: string;

  /**
   * The script async attribute, defaults to true.
   * Currently we only support async script
   */
  async?: boolean;
};

export type UseScriptProps = LoadScriptArguments & {
  /**
   * A function to provide custom logic to determine if the script is already loaded and ready to be used.
   *
   * This helps to avoid loading a script multiple times, and also helps us determine
   * if we can start using the script, since `script` tag loaded in DOM does not mean
   * that the content is ready.
   *
   * e.g. `() => typeof window !== "undefined" && typeof window.grecaptcha !== "undefined"`
   * @returns boolean
   */
  checkScriptIsLoaded?: () => boolean;

  /**
   * A callback function to run when the script is loaded
   * @returns void
   */
  onLoad?: () => void;

  type?: string;
};

/**
 * Custom hook that adds a script tag to the DOM, loads the script asynchronously
 * and runs a custom callback function on load
 *
 * @param {UseScriptProps} useScriptProps properties of script to be loaded and callback functions
 * @returns an object with single boolean value `isScriptReady` which represents
 * whether the script is successfully loaded.
 */
export default function useScript({
  src,
  id,
  onLoad,
  async = true,
  checkScriptIsLoaded = () => false,
}: UseScriptProps) {
  const [isScriptReady, setIsScriptReady] = useState(false);

  useEffect(() => {
    async function loadScript({ src, id, async = true }: LoadScriptArguments) {
      return new Promise<LoadScriptArguments>((resolve) => {
        const script = document.createElement("script");

        script.id = id;
        script.dataset.testid = id;
        script.src = src;
        script.async = async;
        script.onload = () => {
          resolve({ src, id, async });
        };
        document.head.appendChild(script);
      });
    }

    if (typeof window !== "undefined") {
      if (checkScriptIsLoaded()) {
        setIsScriptReady(true);
        return;
      }

      if (document.getElementById(id)) {
        return;
      }

      loadScript({
        src,
        id,
        async,
      })
        .then(() => {
          setIsScriptReady(true);
          if (typeof onLoad == "function") {
            onLoad();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [src, id, async, onLoad, setIsScriptReady, checkScriptIsLoaded]);

  return { isScriptReady };
}
