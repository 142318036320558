import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@src/app/types/PageMetaData";

export type HoroscopeMetaDataProps = {
  sectionName: string;
  sectionTamilName: string;
};

export function HoroscopeMetaData({
  sectionName,
  sectionTamilName,
}: HoroscopeMetaDataProps): PageMetaDataValues {
  const metaTitle = `${sectionTamilName} | Tamil Murasu`;
  const metaDescription = "";

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: sectionName,
  };

  const gaData: GADataProps = {
    level2: sectionName,
    title: `${sectionName.replace("-", "_")}_index`,
    contentcat: 1,
    contenttype: undefined,
  };

  return {
    gaData,
    metaTags,
  };
}
