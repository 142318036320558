import { ADS_DOMAIN } from "@helper/getEnvVariables";
import { adSlotSizes } from "@src/app/components/Elements/Advertisement/AdsConstants";
import adblockDetectorScript from "@src/app/hooks/useLoadedTags/scripts/adblockDetector";
import adsCheckerScript from "@src/app/hooks/useLoadedTags/scripts/adsChecker";
import betteradsHead2Script from "@src/app/hooks/useLoadedTags/scripts/betteradsHead2Script";
import betteradsHeadScript from "@src/app/hooks/useLoadedTags/scripts/betteradsHeadScript";
import betteradsScript from "@src/app/hooks/useLoadedTags/scripts/betteradsScript";
import useScript from "@src/app/hooks/useScript/index";
import useScriptFunction from "@src/app/hooks/useScriptFunction";
import { AdvertisementTypeEnum } from "@src/app/types/Ads.d";
import { useEffect, useState } from "react";

export default function useLoadedTags() {
  const [isPrestitialEnabled, setIsPrestitialEnabled] = useState<
    boolean | undefined
  >(undefined);
  const [isCatfishEnabled, setIsCatfishEnabled] = useState<boolean | undefined>(
    undefined,
  );
  const [isOutbrainEnabled, setIsOutbrainEnabled] = useState<
    boolean | undefined
  >(undefined);

  useScript({
    id: "ads-checker-script",
    src: ADS_DOMAIN + "/tag/ads/ads_checker.js",
    type: "text/javascript",
  });

  useScript({
    id: "suid-script",
    src: "https://dsuwzj1tch87b.cloudfront.net/suid/suid.min.js",
    type: "text/javascript",
  });

  useScript({
    id: "smx-prebid-script",
    src: ADS_DOMAIN + "/tag/smx/prebid.js",
    type: "text/javascript",
  });

  useScriptFunction({
    id: "ads-checker-custom-script",
    value: adsCheckerScript,
    portion: "head",
  });

  useScriptFunction({
    id: "ad-block-detector-script",
    value: adblockDetectorScript,
    portion: "head",
  });

  useEffect(() => {
    // Make sure that googletag.cmd exists.
    window.googletag = (window.googletag as typeof googletag | undefined) || {};
    ((window.googletag as typeof googletag).cmd as googletag.CommandArray) =
      ((window.googletag as typeof googletag).cmd as
        | googletag.CommandArray
        | undefined) || [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    window.googletag.slots = window.googletag.slots || {};

    // Correct: Queueing the callback on the command queue.
    googletag.cmd.push(function () {
      const dfpTagsObj = googletag
        .pubads()
        .getSlots()
        .map((_slot) => {
          const id = _slot.getSlotElementId().replaceAll("dfp-ad-", "");

          const sizeId: string = id.startsWith("lb") ? "lb1" : id;

          const size = Object.keys(
            adSlotSizes[sizeId] as googletag.GeneralSize,
          ).map((_, index) => {
            const sizes = adSlotSizes[sizeId][index];

            return sizes.toString().replaceAll(",", "x");
          });

          const loadedDfpTag = {
            [id]: {
              adunit: _slot.getAdUnitPath(),
              slotname: _slot.getSlotElementId(),
              size: size.join(","),
            },
          };

          // Added googletag.slots so ads team can feed from it.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.googletag.slots[
            _slot.getSlotElementId().replaceAll("dfp-ad-", "")
          ] = _slot;

          return loadedDfpTag;
        });

      const loadedDfpTags: Partial<Record<AdvertisementTypeEnum, object>> = {};

      dfpTagsObj.forEach(
        (item: Partial<Record<AdvertisementTypeEnum, object>>) => {
          const key = Object.keys(item).at(0) as
            | AdvertisementTypeEnum
            | undefined;

          key && (loadedDfpTags[key] = item[key]);
        },
      );

      // Load dfpTagsScript.
      const loadDfpTagsScriptId = "loadDfpTagsScript";
      const loadDfpTagsScriptComponent: HTMLElement | null =
        document.getElementById(loadDfpTagsScriptId);

      if (!loadDfpTagsScriptComponent) {
        const loadDfpTagsScript = document.createElement("script");

        loadDfpTagsScript.id = loadDfpTagsScriptId;
        loadDfpTagsScript.dataset.testid = loadDfpTagsScriptId;
        loadDfpTagsScript.innerHTML = `var loaded_dfp_tags = '${JSON.stringify(
          loadedDfpTags,
        )}';`;
        loadDfpTagsScript.type = "text/javascript";
        document.body.appendChild(loadDfpTagsScript);
      }

      // Load betteradsHeadScript.
      const loadBetteradsHeadScriptId = "betteradsHeadScript";

      if (!document.getElementById(loadBetteradsHeadScriptId)) {
        const loadBetteradsHeadScript = document.createElement("script");

        loadBetteradsHeadScript.id = loadBetteradsHeadScriptId;
        loadBetteradsHeadScript.dataset.testid = loadBetteradsHeadScriptId;
        loadBetteradsHeadScript.innerHTML = betteradsHeadScript;
        loadBetteradsHeadScript.type = "text/javascript";
        document.body.appendChild(loadBetteradsHeadScript);
      }

      // Load smx_prebid.js
      const loadSmxPrebidScriptId = "loadSmxPrebidScript";

      if (!document.getElementById(loadSmxPrebidScriptId)) {
        const loadSmxPrebidScript = document.createElement("script");

        loadSmxPrebidScript.id = loadSmxPrebidScriptId;
        loadSmxPrebidScript.dataset.testid = loadSmxPrebidScriptId;
        loadSmxPrebidScript.src = ADS_DOMAIN + "/tag/smx/smx_prebid.js";
        loadSmxPrebidScript.onload = () => {
          if (typeof window === "undefined") return;
          setIsPrestitialEnabled(window.prestitial_enabled === 1);
          setIsCatfishEnabled(window.catfish_enabled === 1);
          setIsOutbrainEnabled(window.outbrain_enable === 1);
        };
        document.body.appendChild(loadSmxPrebidScript);
      }

      // Load betteradsScript.
      const loadBetteradsScriptId = "betteradsScript";

      if (!document.getElementById(loadBetteradsScriptId)) {
        const loadBetteradsScript = document.createElement("script");

        loadBetteradsScript.id = loadBetteradsScriptId;
        loadBetteradsScript.dataset.testid = loadBetteradsScriptId;
        loadBetteradsScript.innerHTML = betteradsScript;
        loadBetteradsScript.type = "text/javascript";
        document.body.appendChild(loadBetteradsScript);
      }

      // Load betteradsHead2Script.
      const loadBetteradsHead2ScriptId = "betteradsHead2Script";
      if (!document.getElementById(loadBetteradsHead2ScriptId)) {
        const loadBetteradsHead2Script = document.createElement("script");

        loadBetteradsHead2Script.id = loadBetteradsHead2ScriptId;
        loadBetteradsHead2Script.dataset.testid = loadBetteradsHead2ScriptId;
        loadBetteradsHead2Script.innerHTML = betteradsHead2Script;
        loadBetteradsHead2Script.type = "text/javascript";
        document.body.appendChild(loadBetteradsHead2Script);
      }
    });
  }, []);

  return { isPrestitialEnabled, isCatfishEnabled, isOutbrainEnabled };
}
