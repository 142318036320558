import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function PodcastMetaData(section: string): PageMetaDataValues {
  const gaData: GADataProps = {
    level2: section,
    title: `${section}_index`,
    contentcat: 1,
    contenttype: undefined,
  };

  const metaTags: MetaTagsProps = {
    title: "வலையொளி | Tamil Murasu",
    description:
      "வலையொளி |  அண்மையச் செய்திகள் - Get Latest News in Singapore, Tamilnadu, India, World & Sports News in Tamil. சிங்கப்பூர், தமிழ்நாடு, இந்தியா, உலக மற்றும் விளையாட்டு அண்மைய செய்திகளில் தமிழில் சமீபத்தியச் செய்திகளைப் பெறுங்கள்.",
    slug: section,
  };

  return {
    gaData,
    metaTags,
  };
}
