import { isThisUrlFrom } from "@helper/utils";

import DataWrapperEmbed from "../DataWrapperEmbed";
import FacebookEmbedCustom from "../FacebookEmbedCustom";
import InstagramEmbedCustom from "../InstagramEmbedCustom";
import TiktokEmbedCustom from "../TiktokEmbedCustom";
import XEmbedCustom from "../XEmbedCustom";
import YouTubeEmbedCustom from "../YouTubeEmbedCustom";

export default function SocialEmbedFactory(source: string) {
  if (isThisUrlFrom(source, "tiktok.com")) return TiktokEmbedCustom;

  if (
    isThisUrlFrom(source, "youtube.com") ||
    isThisUrlFrom(source, "youtu.be")
  ) {
    return YouTubeEmbedCustom;
  }

  if (isThisUrlFrom(source, "facebook.com")) return FacebookEmbedCustom;

  if (isThisUrlFrom(source, "twitter.com") || isThisUrlFrom(source, "x.com")) {
    return XEmbedCustom;
  }

  if (isThisUrlFrom(source, "instagram.com")) return InstagramEmbedCustom;

  if (isThisUrlFrom(source, "datawrapper")) return DataWrapperEmbed;
}
