const permutiveSphl = `
var permutive_keys = {
  articleid: "",
  section: "",
  chapter1: "",
  contentcat: "",
  contenttype: "",
  gsKeywords: [],
  keywords: [],
  sph_bs_list: [],
  sph_iab_list: [],
  sph_seg: [],
  story_threads: "",
  title: "",
  visitorcat: ""
};

per_userid = "";

if (typeof gtmdataLayer != 'undefined') {
  if (typeof gtmdataLayer.at != 'undefined' && gtmdataLayer.at != "") {per_userid=gtmdataLayer.at;}
  if (typeof gtmdataLayer.articleid != 'undefined' && gtmdataLayer.articleid != "") {permutive_keys.articleid=gtmdataLayer.articleid;}
  if (typeof gtmdataLayer.section != 'undefined' && gtmdataLayer.section != "") {permutive_keys.chapter1 = gtmdataLayer.section;}
  if (typeof gtmdataLayer.chapter1 != 'undefined' && gtmdataLayer.chapter1 != "") {permutive_keys.chapter2=gtmdataLayer.chapter1;}
  if (typeof gtmdataLayer.keyword != 'undefined' && gtmdataLayer.keyword != "") { permutive_keys.keywords=gtmdataLayer.keyword.toLowerCase().split(',').map(function(item) {return item.trim();});}
  if (typeof gtmdataLayer.sph_bs != 'undefined' && gtmdataLayer.sph_bs != "") { permutive_keys.sph_bs_list=gtmdataLayer.sph_bs.toLowerCase().split(',');}
  if (typeof gtmdataLayer.sph_iab != 'undefined' && gtmdataLayer.sph_iab != "") { permutive_keys.sph_iab_list=gtmdataLayer.sph_iab.toLowerCase().split(',');}
  if (typeof gtmdataLayer.sph_seg != 'undefined' && gtmdataLayer.sph_seg != "") {permutive_keys.sph_seg=gtmdataLayer.sph_seg.toLowerCase().split(',');}
  if (typeof gtmdataLayer.story_threads != 'undefined' && gtmdataLayer.story_threads != "") {permutive_keys.story_threads=gtmdataLayer.story_threads;}
  if (typeof gtmdataLayer.title != 'undefined' && gtmdataLayer.title != "") {permutive_keys.title=gtmdataLayer.title;}
  if (typeof per_getcookie('sph_seg') != 'undefined' && per_getcookie('sph_seg') != "") {permutive_keys.sph_seg=per_getcookie('sph_seg').toLowerCase().split(',');}
  if (typeof gtmdataLayer.visitorcat != 'undefined' && gtmdataLayer.visitorcat != "") {
    switch (gtmdataLayer.visitorcat) {
      case '1':
        permutive_keys.visitorcat='anonymous';break;
      case '2':
        permutive_keys.visitorcat='subscriber';break;
      case '3':
        permutive_keys.visitorcat='registered';break;
      default:
        permutive_keys.visitorcat='anonymous';
    }
  }

  if (typeof gtmdataLayer.contenttype != 'undefined' && gtmdataLayer.contenttype != "") {
    permutive_keys.contenttype = gtmdataLayer.contenttype;
  }

  if (typeof gtmdataLayer?.dfp?.section != 'undefined' && gtmdataLayer.dfp.section != "") {
    permutive_keys.chapter1 = gtmdataLayer.dfp.section;
    permutive_keys.contentcat = 'free';
  }
}

if (typeof gs_channels != 'undefined' && gs_channels != "") {permutive_keys.gsKeywords=gs_channels.join('|').toLowerCase().split('|');}

function per_getcookie(cname){
  if(document.cookie.length>0){
    c_start=document.cookie.indexOf(cname+"=");
    if(c_start!=-1){
      c_start=c_start+cname.length+1;
      c_end=document.cookie.indexOf(";",c_start);
      if(c_end==-1){c_end=document.cookie.length}
      return unescape(document.cookie.substring(c_start,c_end))
    }
  }
  return ""
}

var per_suid=per_getcookie('suid');
var mysph_lr = per_getcookie('mysph_lr');
var ptid = typeof mysph_lr != 'undefined' ? mysph_lr : null;

!function (e, o, n, i) { if (!e) { e = e || {}, window.permutive = e, e.q = []; var t = function () { return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (e) { return (e ^ (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(1))[0] & 15 >> e / 4).toString(16) }) }; e.config = i || {}, e.config.apiKey = o, e.config.workspaceId = n, e.config.environment = e.config.environment || "production", (window.crypto || window.msCrypto) && (e.config.viewId = t()); for (var g = ["addon", "identify", "track", "trigger", "query", "segment", "segments", "ready", "on", "once", "user", "consent"], r = 0; r < g.length; r++) { var w = g[r]; e[w] = function (o) { return function () { var n = Array.prototype.slice.call(arguments, 0); e.q.push({ functionName: o, arguments: n }) } }(w) } } }(window.permutive, "ab403253-b305-47fa-a31b-3efb2473166f", "5f876161-9740-4cc8-9b64-4585990b2690", {});
window.googletag = window.googletag || {}, window.googletag.cmd = window.googletag.cmd || [], window.googletag.cmd.push(function () { if (0 === window.googletag.pubads().getTargeting("permutive").length) { var e = window.localStorage.getItem("_pdfps"); window.googletag.pubads().setTargeting("permutive", e ? JSON.parse(e) : []); var o = window.localStorage.getItem("permutive-id"); window.googletag.pubads().setTargeting("puid", o ? o : ""); window.googletag.pubads().setTargeting("ptime", Date.now().toString()); window.permutive.config.viewId && window.googletag.pubads().setTargeting("prmtvvid", window.permutive.config.viewId); window.permutive.config.workspaceId && window.googletag.pubads().setTargeting("prmtvwid", window.permutive.config.workspaceId); } });

  permutive.identify([
  {
    "id": per_userid,
    "tag": "userid",
    "priority": 0
  },
  {
    "id": ptid,
    "tag": "ptid",
    "priority": 1
  },
  {
    "id": per_suid,
    "tag": "suid",
    "priority": 2
  }
  ])

  permutive.addon('web', {
    page: permutive_keys
  });

// Permutive Ready check with Timeout function definition
window.permutive.readyWithTimeout = function (e, i, t) { var u = !1, n = function () { u || (e(), u = !0) }; (t = t || 1 / 0) !== 1 / 0 && window.setTimeout(n, t), permutive.ready(n, i) };
`;

const permutiveScript =
  "https://5f876161-9740-4cc8-9b64-4585990b2690.edge.permutive.app/5f876161-9740-4cc8-9b64-4585990b2690-web.js";

export { permutiveScript, permutiveSphl };
