import ChevronWhiteImage from "@assets/Chevron_white.svg";
import ChevronImage from "@assets/video_widget/Chevron.webp";
import ThumbnailCard from "@elements/Card/Thumbnail/ThumbnailCard";
import { BrightcoveVideo } from "@src/app/components/BrightcoveVideo/BrightcoveVideo";
import Heading from "@src/app/components/Elements/Typography/Heading";
import useSwrApi from "@src/app/hooks/useSwrApi";
import useWindowDimensions from "@src/app/hooks/useWindowDimensions";
import { type LatestVideoResponse } from "@transformer/useBrightcoveResponse";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import { ToggleButton } from "../ToggleButton";

export const VideoPlaylistSection = () => {
  const { data } = useSwrApi<LatestVideoResponse[]>(`latest-videos-playlist`);
  const videosList = data || [];
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);
  const [scrollAmount, setScrollAmount] = useState(0);

  const handleOnEndedVideo = () => {
    const index = currentVideoIndex + 1;
    if (index === videosList.length - 1) {
      setCurrentVideoIndex(0);
    } else {
      setCurrentVideoIndex(index);
    }
  };

  const onNextVideo = () => {
    const index = Math.min(cardIndex + 3, videosList.length - 1);
    if (index === videosList.length - 1) return;
    setCardIndex(index);

    const scrollContainer = document.getElementById("scroll-container");
    if (scrollContainer) {
      const scrollMax = scrollContainer.clientWidth;
      const newScroll = scrollAmount + 690;
      setScrollAmount(newScroll);
      scrollContainer.scrollTo({
        top: 0,
        left: Math.max(newScroll, scrollMax),
        behavior: "smooth",
      });
    }
  };

  const onPreviousVideo = () => {
    const index = Math.max(cardIndex - 3, 0);
    setCardIndex(index);

    const scrollContainer = document.getElementById("scroll-container");
    if (scrollContainer) {
      const scrollMin = 0;
      const newScroll = scrollAmount - 690;
      setScrollAmount(newScroll);
      scrollContainer.scrollTo({
        top: 0,
        left: Math.max(newScroll, scrollMin),
        behavior: "smooth",
      });
    }
  };

  const handleOnClickThumbnail = (index: number) => () => {
    setCurrentVideoIndex(index);
    containerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const { width } = useWindowDimensions();
  const isMobile = width <= 480;

  return (
    <div className="overflow-hidden lg:pr-[30px] pb-5 border-b border-pink-300">
      <Heading
        Element="h2"
        className="text-red-100 text-2.5md font-bold mt-5 mb-5 hover:underline hover:cursor-pointer hover:text-red-200"
      >
        <Link reloadDocument to={"/videos"} target="_blank">
          காணொளிகள்
          <img
            alt="toggle-button-chevie"
            height={24}
            width={24}
            className="pl-100 inline"
            src={ChevronImage}
          />
        </Link>
      </Heading>
      {videosList[currentVideoIndex]?.id && (
        <BrightcoveVideo
          videoId={videosList[currentVideoIndex].id}
          forwardedRef={containerRef}
          onEnded={handleOnEndedVideo}
          nextPlaylist={handleOnEndedVideo}
        />
      )}
      <div className="bg-gray-150 lg:mt-5 p-5 pr-0">
        <div className="hidden lg:flex justify-between">
          <Heading
            Element="h3"
            className="text-red-500 text-md font-bold hover:no-underline hover:cursor-pointer"
          >
            <Link reloadDocument to={"/videos"} target="_blank">
              மேலும் காணொளிகள்
              <img
                alt="toggle-button-chevie"
                height={24}
                width={24}
                className="pl-100 inline"
                src={ChevronWhiteImage}
              />
            </Link>
          </Heading>
          <div className="flex gap-2.5 pr-5">
            <ToggleButton
              isPrevious
              isDisabled={cardIndex === 0}
              onClick={onPreviousVideo}
            />
            <ToggleButton
              isDisabled={cardIndex + 2 === videosList.length}
              onClick={onNextVideo}
            />
          </div>
        </div>

        <div
          id="scroll-container"
          className={twMerge(
            "flex overflow-y-hidden gap-2.5",
            isMobile ? "overflow-x-scroll" : "overflow-x-hidden",
          )}
        >
          {videosList.map((video, index) => (
            <ThumbnailCard<LatestVideoResponse>
              key={index}
              handleOnClickThumbnail={handleOnClickThumbnail(index)}
              variant="video"
              {...video}
              nowPlaying={currentVideoIndex === index}
              customCardStyles={
                isMobile
                  ? `flex-col w-[180px] h-[310px]`
                  : "flex-col w-[220px] h-[340px]"
              }
              isDark
              videoPosterDimensions={
                isMobile ? "h-[180px] w-[180px]" : "h-[220px] w-[220px]"
              }
              isHideDateTime
            />
          ))}
        </div>
      </div>
    </div>
  );
};
