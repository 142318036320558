import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import fetchCaas from "@src/app/components/helper/fetchCaas";
import fetchTMApi from "@src/app/components/helper/fetchTMApi";
import { getOsByExcludeMultipleSections } from "@src/app/query/query";
import { MoreArticlesRequestData } from "@src/app/types/MoreArticlesApi";
import { OSResponse, PartialCueArticle } from "@src/app/types/Opensearch";
import getOSProcessedArticleData, {
  type ProcessedArticleData,
} from "@transformer/useOsResponse";

const excludedSections = [
  "baalar-murasu",
  "events",
  "horoscope-daily",
  "horoscope-weekly",
  "moe",
  "photo-gallery",
  "remedy",
  "tm-picks",
];

export const fetchRecentArticlesData = async (): Promise<
  TRouteWithoutRedirect<ProcessedArticleData[], string>
> => {
  try {
    const RecentArticleList = (await fetchCaas({
      searchQuery: getOsByExcludeMultipleSections(excludedSections, 5),
    })) as OSResponse<PartialCueArticle>;

    const hits = RecentArticleList.payload.hits.total.value;
    if (hits === 0) {
      return { type: ResponseType.CLIENT_ERROR, statusCode: 404 };
    }

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: getOSProcessedArticleData(RecentArticleList),
    };
  } catch (e) {
    const error = e as Error;
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchMoreStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  try {
    const response: ProcessedArticleData[] = await fetchTMApi(
      "more-excluded-sections-stories",
      "POST",
      {
        sections: excludedSections,
        sort: requestData.sort,
        size: 5,
      },
    );

    return response;
  } catch (error) {
    return [];
  }
}
