import { ReactNode } from "react";

export type HeadingElements = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type HeadingProps = {
  Element?: HeadingElements;
  children?: ReactNode;
  className?: string;
};
export default function Heading({
  Element = "h1",
  children,
  className,
}: HeadingProps) {
  return <Element className={className}>{children}</Element>;
}
