import ChevronImage from "@assets/video_widget/Chevron.webp";
import ArticleCard from "@src/app/components/Card/ArticleCard";
import Heading from "@src/app/components/Elements/Typography/Heading";
import { ProcessedArticleData } from "@src/app/components/helper/transformer/useOsResponse";
import { Link } from "react-router-dom";

export const RecentArticlesSection = ({
  recentArticles,
}: {
  recentArticles: ProcessedArticleData[];
}) => {
  return (
    <div className="border-b border-pink-300">
      <Heading
        Element="h2"
        className="text-red-100 text-2.5md font-bold mt-5 hover:underline hover:cursor-pointer hover:text-red-200"
      >
        <Link reloadDocument to={"/recent-articles"} target="_blank">
          அண்மைய செய்திகள்
          <img
            alt="toggle-button-chevie"
            height={26}
            width={26}
            className="pl-100 inline"
            src={ChevronImage}
          />
        </Link>
      </Heading>
      <div className="flex flex-col lg:flex-row">
        {recentArticles
          .filter((_, i) => i === 0)
          .map((article) => (
            <ArticleCard
              key={article.id}
              displayWidth={390}
              displayHeight={260}
              elements={{
                hasParagraph: false,
                hasDate: false,
                hasDateInAgo: true,
              }}
              {...article}
              overrideStyle={{
                cardWrapper: "w-full lg:w-fit flex flex-col lg:border-b-0",
                paragraphStyle: "lg:text-2sm",
                titleStyle:
                  "font-semibold font-primary text-sm2 text-gray-100 hover:text-gray-100",
                imageWrapperStyle:
                  "w-full max-w-full lg:w-84 h-55 lg:h-64 lg:max-h-64 sm:h-100 sm:w-full",
                titleWrapper: "gap-0",
                imageStyle: "h-55 lg:h-64 sm:h-100",
              }}
            />
          ))}

        <div className="flex-col lg:px-lg">
          {recentArticles
            .filter((_, i) => i > 0)
            .map((article, i, row) => (
              <ArticleCard
                key={i}
                displayWidth={135}
                displayHeight={90}
                elements={{
                  hasParagraph: false,
                  hasDate: false,
                  hasDateInAgo: true,
                }}
                {...article}
                overrideStyle={{
                  cardWrapper:
                    i + 1 === row.length
                      ? "w-full lg:w-full flex-row-reverse border-b-0"
                      : i === 0
                        ? "w-full lg:w-full flex-row-reverse pb-xs"
                        : "w-full lg:w-full flex-row-reverse py-xs",
                  paragraphStyle: "lg:text-2sm",
                  titleStyle:
                    "font-semibold font-primary text-sm2 text-gray-100 hover:text-gray-100",
                  imageWrapperStyle: "w-23 max-w-40 lg:w-31.5 h-23 sm:w-full",
                  titleWrapper: "gap-0",
                  imageStyle: "w-full h-23",
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
