/**
 * Company: SPHMedia
 * Description: [Brief description of the component]
 */

import { CSSProperties, ReactElement } from "react";

import type { ListItemType } from "./ListItem/ListItem";

export type ListElements = "ul" | "ol";

type ListProps = {
  /** This is a testId prop for the list */
  testId?: string;
  /** This is a style prop for the list */
  style?: CSSProperties;
  className?: string;
  children: ListItemType | ListItemType[];
  Element?: ListElements;
};

/**
 * List
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function List({
  testId,
  style,
  children,
  className,
  Element = "ul",
}: ListProps): ReactElement {
  return (
    <Element data-testid={testId} style={style} className={className}>
      {children}
    </Element>
  );
}
