import NewsletterImage from "@assets/footer/newsletter.webp";
import fetchTMApi from "@helper/fetchTMApi";
import { NewsletterPostResponse } from "@src/app/types/Newsletter";
import { FormEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Heading from "../Elements/Typography/Heading";

export const FooterNewsletterSignup = () => {
  const [email, setEmail] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  useEffect(() => {
    if (errorMsg.length > 0 || successMsg.length > 0) {
      setTimeout(() => {
        setErrorMsg("");
        setSuccessMsg("");
      }, 3000);
    }
  }, [errorMsg, successMsg]);

  async function handleOnSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    // validate email
    if (
      email &&
      getValidEmailRegex().test(email) // Valid email format
    ) {
      try {
        const res = await fetchTMApi("newsletterSignup", "POST", {
          email,
        });
        const data = (await res) as NewsletterPostResponse;
        console.log("DATA", data);
        if (data.msg) {
          setSuccessMsg(data.msg);
          return;
        }

        if (data.errorMsg) {
          setErrorMsg(data.errorMsg);
          return;
        }
      } catch (e: unknown) {
        console.log("failed with error: ", e);
        return;
      }
    } else {
      // Invalid email
      console.log("Invalid email.");
      return;
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  function getValidEmailRegex() {
    return /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  }

  return (
    <div className="lg:flex xl:max-w-xl lg:max-w-lg md:max-w-xm sm:max-w-xs w-full mx-auto py-4 pt-500 xs:px-sm xl:px-xxs font-primary max-h-fill lg:max-h-52 lg:mb-[10px]">
      <div className="inline-block md:w-8/12">
        <Heading
          Element="h2"
          className="text-red-100 text-md leading-400 font-regular mb-2"
        >
          எங்களுடன் இணைந்திருங்கள்!
        </Heading>
        <p className="w-full font-normal text-xs leading-400 text-gray-100 mt-3 mb-3">
          மின்னஞ்சல் பதிவு செய்வதன் மூலம் செய்திகள், நிகழ்ச்சிகள் மற்றும் பல
          தகவல்களை மின்னஞ்சல் வழி பெறுவீர்கள்!
        </p>
        {errorMsg ? (
          <div className="w-full py-3 px-5 mb-4 border border-solid border-red-200 rounded text-white-300 bg-pink-100 text-xs font-normal leading-800">
            {errorMsg}
          </div>
        ) : successMsg ? (
          <div className="w-full py-3 px-5 mb-4 border border-solid border-green-200 rounded text-green-300 bg-green-100 text-xs font-normal leading-800">
            {successMsg}
          </div>
        ) : (
          <form
            onSubmit={(e: FormEvent<HTMLFormElement>) => void handleOnSubmit(e)}
            className="flex-col lg:flex lg:flex-row"
          >
            <input
              onChange={handleInputChange}
              className="lg:h-[42px] rounded-[5px] lg:rounded-l-[5px] p-[10px] w-full lg:w-[416px] font-normal text-xs"
              placeholder="மின்னஞ்சல் முகவரியை இங்கு பதிவிடுக!"
            />
            <button className="text-[white] bg-[linear-gradient(90deg,_#F4A63B_0%,_#F5740A_100%)] h-[40px] lg:h-[42px] mt-2 lg:mt-0 w-full lg:w-[150px] p-[10px 20px] rounded-[5px] rounded-r-[5px]">
              அனுப்பு
            </button>
          </form>
        )}
        <div className="w-full font-normal text-xs leading-400 text-gray-100 mt-3 mb-3">
          பதிவு செய்வதன் மூலம்{" "}
          <Link
            reloadDocument
            className="text-red-100 hover:text-red-200 hover:underline"
            to={"https://www.sph.com.sg/tnc/privacy"}
            target="_blank"
          >
            &apos;தனியுரிமை கொள்கை&apos;
          </Link>{" "}
          மற்றும்{" "}
          <Link
            reloadDocument
            className="text-red-100 hover:text-red-200 hover:underline"
            to={"https://www.sph.com.sg/tnc/website"}
            target="_blank"
          >
            &apos;விதிமுறைகள் & நிபந்தனைகள்&apos;
          </Link>{" "}
          ஆகியவற்றுக்கு நீங்கள் உடன்படுகிறீர்கள்.
        </div>
      </div>
      <div className="hidden lg:block lg:px-3.5 xs:py-xs lg:pb-xs md:px-sm text-sm not-italic leading-200 tracking-normal text-gray-100 md:w-4/12 translate-y-[-80px] md:inline-block">
        <img
          alt="newsletter-footer-image"
          className="w-[197px] h-[238px]"
          src={NewsletterImage}
        />
      </div>
    </div>
  );
};
