type FooterCopyrightProps = {
  testId?: string;
};

export function FooterCopyright({ testId }: FooterCopyrightProps) {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div
      data-testid={`${testId}-copyright`}
      className="not-italic text-xs tracking-normal leading-200 text-gray-100"
    >
      MCI (P) 002/04/2024. SPH Digital News Copyright © {year}. SPH Media
      Limited. Co. Regn. No. 202120748H. All Rights Reserved.
    </div>
  );
}
