import { LIVE_RAMP_PID } from "@helper/getEnvVariables";
import sha256 from "crypto-js/sha256";
import Cookies from "js-cookie";
import { useEffect } from "react";

export const COOKIE_SPH_LR = "mysph_lr";
export const COOKIE_SPH_TYPE = "mySPHUserType";
export const COOKIE_LR_ENVELOPE = "_lr_env";
export const COOKIE_LR_EXPIRY = "_lr_exp";
export const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;
export const LIVE_RAMP_URL = "https://api.rlcdn.com/api/identity/v2/envelope";
enum Mode {
  FETCH,
  REFRESH,
}
const MODE_URLS = {
  [Mode.FETCH]: LIVE_RAMP_URL,
  [Mode.REFRESH]: LIVE_RAMP_URL + "/refresh",
};

const isUserLoggedIn = () => {
  return Cookies.get(COOKIE_SPH_LR) !== undefined;
};
const isUserAnonymous = () => {
  return Cookies.get(COOKIE_SPH_TYPE) === "y-anoy";
};
const isLRCookieAvailable = () => {
  return Cookies.get(COOKIE_LR_ENVELOPE) !== undefined;
};
const isLRCookieExpired = () => {
  if (!Cookies.get(COOKIE_LR_EXPIRY)) return true;
  return new Date().getTime() >= Number(Cookies.get(COOKIE_LR_EXPIRY));
};

interface UseLiveRampProps {
  fetchEnvelope: (url: string) => Promise<string | null>;
}
export const useLiveRamp = ({ fetchEnvelope }: UseLiveRampProps) => {
  useEffect(() => {
    async function callFetch() {
      if (!isUserLoggedIn()) {
        return;
      }

      if (isLRCookieAvailable() || isLRCookieExpired()) {
        if (isUserAnonymous()) {
          await callEnvelope(Mode.REFRESH);
          return;
        } else {
          await callEnvelope(Mode.FETCH);
          return;
        }
      }

      if (!isUserAnonymous()) {
        await callEnvelope(Mode.FETCH);
        return;
      }
    }
    void callFetch();
  }, [fetchEnvelope]);
};

async function callEnvelope(mode: Mode): Promise<void> {
  const url = constructLiveRampURL(mode);

  if (url == null) return;

  const value = await fetchEnvelope(url);

  if (value == null) return;

  setLiveRampCookies(value);
}

export const fetchEnvelope = async (url: string): Promise<string | null> => {
  try {
    const response = await fetch(url);
    const json = (await response.json()) as { envelopes: { value: unknown }[] };
    const envelopeValue = json.envelopes[0].value;

    return typeof envelopeValue === "string" ? envelopeValue : null;
  } catch (error) {
    return null;
  }
};

export const constructLiveRampURL = (mode: Mode): string | null => {
  const sphCookie = Cookies.get(COOKIE_SPH_LR);
  const envelopeValue = Cookies.get(COOKIE_LR_ENVELOPE);
  const it = mode === Mode.REFRESH ? "19" : "4";

  let iv;

  if (mode == Mode.REFRESH) {
    if (!envelopeValue) return null;
    iv = envelopeValue;
  } else {
    if (!sphCookie) return null;
    iv = sha256(sphCookie).toString();
  }

  const url = new URL(MODE_URLS[mode]);

  url.searchParams.set("pid", LIVE_RAMP_PID);
  url.searchParams.set("it", it);
  url.searchParams.set("iv", iv);

  return url.toString();
};

export const setLiveRampCookies = (envelope: string) => {
  Cookies.set(COOKIE_LR_ENVELOPE, envelope, {
    expires: 1,
    path: "/",
  });

  const oneDayFromNow = (new Date().getTime() + ONE_DAY_IN_MS).toString();

  Cookies.set(COOKIE_LR_EXPIRY, oneDayFromNow, {
    expires: 1,
    path: "/",
  });
};
