import {
  adSlotSizes,
  midArticleAdSlotSizesInfinteScroll,
  pubPageAdTargeting,
} from "@elements/Advertisement/AdsConstants";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

type CompanionAdsImuProps = {
  uniqueSectionName?: string;
  rootClassName?: string;
  pageAdTargetType: PageAdTargetValue;
  index: number;
};

/**
 * TM Companion Imu1, Imu2 & midadrticle Ad
 * Description: The Imu1,Imu2 & midadrticle component for rendering ads.
 * @name - name of section or page
 */

export function CompanionAdsImu1({
  uniqueSectionName,
  rootClassName,
  pageAdTargetType,
  index,
}: CompanionAdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.IMU1,
        slot: GoogleAdsSlotFactory.imu1(uniqueSectionName),
        id: `dfp-ad-imu1-${index}`,
        adSlotSize: adSlotSizes["imu1"],
        pageNumber: index.toString(),
        pubAdTargeting: [PubPageAdTarget],
      }}
    />
  );
}
export function CompanionAdsImu2({
  uniqueSectionName,
  pageAdTargetType,
  index,
}: CompanionAdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <div className="my-6 lg:h-[600px]">
      <CompanionAdvertisement
        rootClassName={twMerge(
          "flex justify-center lg:sticky lg:top-[130px]",
          "!py-0",
        )}
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.IMU2,
          slot: GoogleAdsSlotFactory.imu2(uniqueSectionName),
          id: `dfp-ad-imu2-${index}`,
          adSlotSize: adSlotSizes["imu2"],
          pageNumber: index.toString(),
          pubAdTargeting: [PubPageAdTarget],
        }}
      />
    </div>
  );
}

export function CompanionAdsMidArticle({
  uniqueSectionName,
  rootClassName,
  pageAdTargetType,
  index,
}: CompanionAdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <CompanionAdvertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      companionAdUnitProps={{
        type: AdvertisementTypeEnum.MIDARTICLESPECIAL,
        slot: GoogleAdsSlotFactory.midarticlespecial(uniqueSectionName),
        id: `dfp-ad-midarticlespecial-${index}`,
        adSlotSize: midArticleAdSlotSizesInfinteScroll["midArticle"],
        pageNumber: index.toString(),
        pubAdTargeting: [PubPageAdTarget],
      }}
    />
  );
}
