import { ReactElement } from "react";

export type HamburgerMenuIconProps = {
  showMenuIcon: boolean;
  expandMenu: boolean;
  onClick: () => void;
};

export function HamburgerMenuIcon({
  showMenuIcon,
  expandMenu,
  onClick,
}: HamburgerMenuIconProps): ReactElement {
  return (
    <>
      {showMenuIcon ? (
        <button
          onClick={onClick}
          className="pl-3 pr-0.5 mt-4xs mr-4xs"
          aria-label="Hamburger-Menu"
        >
          <span className="block w-6.5 h-0.7 my-1 bg-black-100 rounded-sm"></span>
          <span className="block w-6.5 h-0.7 my-1 bg-black-100 rounded-sm"></span>
          <span className="block w-6.5 h-0.7 my-1 bg-black-100 rounded-sm"></span>
        </button>
      ) : null}

      {expandMenu ? (
        <button onClick={onClick} className="pl-2.5 pr-1 mr-4xs pt-1">
          <span className="block w-6.5 h-0.7 my-1 bg-black-100 origin-100 rotate-45"></span>
          <span className="block w-6.5 h-0.7 my-1 bg-black-100 origin-100 -rotate-45"></span>
        </button>
      ) : null}
    </>
  );
}
