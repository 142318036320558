import List from "@elements/List/List";
import { ListItem } from "@elements/List/ListItem/ListItem";
import type { ReactElement } from "react";

const handleClick = (url: string) => {
  window.location.href = url; // This will force a full page reload
};

type BreadCrumbItem = {
  breadcrumbLabel?: string;
  breadcrumbUrl?: string;
};

type Props = Readonly<{
  items: BreadCrumbItem[];
}>;

export default function BreadCrumb({ items }: Props): ReactElement {
  return (
    <nav className="mb-4">
      <List className="hidden lg:inline-flex">
        {items.map((item, id) => (
          <ListItem
            key={id}
            className="text-gray-700 text-xs font-normal font-sans"
          >
            {item.breadcrumbUrl !== undefined ? (
              <>
                <a
                  href={item.breadcrumbUrl}
                  className="hover:text-gray-700 hover:underline"
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default navigation
                    handleClick(item.breadcrumbUrl || "#"); // Force a page reload
                  }}
                >
                  {item.breadcrumbLabel}
                </a>
                <span className="px-2 text-3xs bottom-1px relative">{">"}</span>
              </>
            ) : (
              <span className="text-gray-950">{item.breadcrumbLabel}</span>
            )}
          </ListItem>
        ))}
      </List>
    </nav>
  );
}
