const NEWSLETTER_CONTACT_LIST_ID = "41065259";
const NEWSLETTER_COOKIE_MOBILE = "__mobile_webview";

const NEWSLETTER_SIGN_UP_SUCCESS_MSG = "குழுவில் இணைந்ததற்கு நன்றி."; // Thanks for joining the group.
const NEWSLETTER_UPDATE_SUCCESS_MSG =
  "குழுவில் இணைந்ததற்கு நன்றி.உங்களது கணக்கு புதுப்பிக்கப்பட்டுவிட்டது."; // Thank you for joining the group. Your account has been updated.
const NEWSLETTER_SIGN_UP_FAILED_ERROR_MSG = "Failed to create contact.";
const NEWSLETTER_UPDATE_FAILED_ERROR_MSG = "Failed to update contact.";
const NEWSLETTER_EMAIL_FORMAT_ERROR_MSG = "Email has an invalid format.";
const NEWSLETTER_FAILED_ERROR_MSG = "Newsletter sign-up failed.";

export {
  NEWSLETTER_CONTACT_LIST_ID,
  NEWSLETTER_COOKIE_MOBILE,
  NEWSLETTER_EMAIL_FORMAT_ERROR_MSG,
  NEWSLETTER_FAILED_ERROR_MSG,
  NEWSLETTER_SIGN_UP_FAILED_ERROR_MSG,
  NEWSLETTER_SIGN_UP_SUCCESS_MSG,
  NEWSLETTER_UPDATE_FAILED_ERROR_MSG,
  NEWSLETTER_UPDATE_SUCCESS_MSG,
};
