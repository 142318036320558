import type { ReactElement } from "react";

import { HEADER_SOCIAL_MENU } from "../HeaderMenuConstants";
import { HeaderMenuList } from "../HeaderMenuList";

export type SocialProps = {
  testId: string;
};

export function SocialMedia({ testId }: SocialProps): ReactElement {
  return (
    <>
      <HeaderMenuList
        testId={testId}
        items={HEADER_SOCIAL_MENU}
        type="social"
        isHamburger={false}
      />
    </>
  );
}
