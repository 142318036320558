import facebookIcon from "@assets/social_media/facebook.webp";
import linkIcon from "@assets/social_media/link.webp";
import whatsappIcon from "@assets/social_media/whatsapp.webp";
import xIcon from "@assets/social_media/x.webp";
import { removeLeadingSlash } from "@helper/removeTrailingLeadingSlash";
import { getWindowOrigin } from "@src/app/components/helper/utils";

import LinkIcon from "../Icon/LinkIcon";

export type SocialVariant =
  | "facebook"
  | "copylink"
  | "x"
  | "whatsapp"
  | "plainWhatsapp";

export type ShareSocialProps = {
  variant: SocialVariant;
  pageTitle?: string;
  pagePath: string;
  width?: string;
};

export default function ShareSocial({
  variant,
  pageTitle,
  pagePath,
}: ShareSocialProps) {
  const origin = getWindowOrigin();
  const shareTitle = pageTitle && encodeURIComponent(pageTitle);
  const sharePath = encodeURIComponent(
    `${origin}/${removeLeadingSlash(pagePath)}`,
  );
  const xDestination = shareTitle
    ? `${sharePath}&text=${shareTitle}`
    : sharePath;
  const whatsappDestination = shareTitle
    ? `${shareTitle}%0A${sharePath}`
    : sharePath;
  switch (variant) {
    case "facebook":
      return (
        <div className="flex items-center justify-center  bg-pink-500 rounded-whatsapp h-[35px] w-[35px] rounded-full gap-1">
          <LinkIcon
            destination={`https://www.facebook.com/sharer/sharer.php?u=${sharePath}`}
            src={facebookIcon}
            alt="facebook"
            height="35px"
            width="35px"
            className="p-1"
          />
        </div>
      );
    case "x":
      return (
        <div className="flex items-center justify-center bg-pink-500 rounded-whatsapp h-[35px] w-[35px] rounded-full gap-1">
          <LinkIcon
            destination={`https://twitter.com/intent/tweet?url=${xDestination}`}
            src={xIcon}
            alt="x"
            height="35px"
            width="35px"
            className="p-1"
          />
        </div>
      );
    case "whatsapp":
      return (
        <div className="flex items-center justify-center  bg-pink-500 rounded-whatsapp h-[35px] w-[35px] rounded-full gap-1">
          <LinkIcon
            destination={`https://wa.me/?text=${whatsappDestination}`}
            src={whatsappIcon}
            alt="whatsapp"
            height="35px"
            width="35px"
            className="p-1"
          />
        </div>
      );
    case "copylink":
      return (
        <div className="flex items-center justify-center  bg-pink-500 rounded-whatsapp h-[35px] w-[35px] rounded-full gap-1">
          <LinkIcon
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              void navigator.clipboard.writeText(`${origin}${pagePath}`);
              alert("Link Copied!");
            }}
            destination={"#"}
            src={linkIcon}
            alt="copylink"
            height="35px"
            width="35px"
            className="p-1"
          />
        </div>
      );
    case "plainWhatsapp":
      return (
        <LinkIcon
          height="35px"
          width="35px"
          destination={`https://wa.me/?text=${whatsappDestination}`}
          src={linkIcon}
          alt="sharelink"
        />
      );
    default:
      return null;
  }
}
