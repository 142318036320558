import imgAquarius from "@assets/horoscope/aquarius.svg";
import imgAries from "@assets/horoscope/aries.svg";
import imgCancer from "@assets/horoscope/cancer.svg";
import imgCapricorn from "@assets/horoscope/capricorn.svg";
import imgGemini from "@assets/horoscope/gemini.svg";
import imgLeo from "@assets/horoscope/leo.svg";
import imgLibra from "@assets/horoscope/libra.svg";
import imgPisces from "@assets/horoscope/pisces.svg";
import imgSagittarius from "@assets/horoscope/sagittarius.svg";
import imgScorpio from "@assets/horoscope/scorpio.svg";
import imgTaurus from "@assets/horoscope/taurus.svg";
import imgVirgo from "@assets/horoscope/virgo.svg";
import { getZodiacId } from "@src/app/components/helper/getZodiacId";

const ZODIAC_ARIES = "aries";
const ZODIAC_TAURUS = "taurus";
const ZODIAC_GEMINI = "gemini";
const ZODIAC_CANCER = "cancer";
const ZODIAC_LEO = "leo";
const ZODIAC_VIRGO = "virgo";
const ZODIAC_LIBRA = "libra";
const ZODIAC_SCORPIO = "scorpio";
const ZODIAC_SAGITTARIUS = "sagittarius";
const ZODIAC_CAPRICORN = "capricorn";
const ZODIAC_AQUARIUS = "aquarius";
const ZODIAC_PISCES = "pisces";

export {
  ZODIAC_AQUARIUS,
  ZODIAC_ARIES,
  ZODIAC_CANCER,
  ZODIAC_CAPRICORN,
  ZODIAC_GEMINI,
  ZODIAC_LEO,
  ZODIAC_LIBRA,
  ZODIAC_PISCES,
  ZODIAC_SAGITTARIUS,
  ZODIAC_SCORPIO,
  ZODIAC_TAURUS,
  ZODIAC_VIRGO,
};

export type ZodiacPropType = {
  en: string;
  ta: string;
  img: string;
};

export const HOROSCOPE_DAILY_ID = getZodiacId("76094", "1200956", "1170810");

export const ZODIACS = [
  {
    id: getZodiacId("76099", "1200971", "1129542"),
    en: ZODIAC_ARIES,
    ta: "மேஷம்",
    img: imgAries,
  },
  {
    id: getZodiacId("76096", "1200964", "1129569"),
    en: ZODIAC_TAURUS,
    ta: "ரிஷபம்",
    img: imgTaurus,
  },
  {
    id: getZodiacId("76102", "1201011", "1129592"),
    en: ZODIAC_GEMINI,
    ta: "மிதுனம்",
    img: imgGemini,
  },
  {
    id: getZodiacId("76100", "1201000", "1129644"),
    en: ZODIAC_CANCER,
    ta: "கடகம்",
    img: imgCancer,
  },
  {
    id: getZodiacId("76101", "1201003", "1129669"),
    en: ZODIAC_LEO,
    ta: "சிம்மம்",
    img: imgLeo,
  },
  {
    id: getZodiacId("76103", "1201014", "1129692"),
    en: ZODIAC_VIRGO,
    ta: "கன்னி",
    img: imgVirgo,
  },
  {
    id: getZodiacId("76106", "1201020", "1129714"),
    en: ZODIAC_LIBRA,
    ta: "துலாம்",
    img: imgLibra,
  },
  {
    id: getZodiacId("76107", "1201021", "1129736"),
    en: ZODIAC_SCORPIO,
    ta: "விருச்சிகம்",
    img: imgScorpio,
  },
  {
    id: getZodiacId("76104", "1201016", "1129758"),
    en: ZODIAC_SAGITTARIUS,
    ta: "தனுசு",
    img: imgSagittarius,
  },
  {
    id: getZodiacId("76108", "1201022", "1130663"),
    en: ZODIAC_CAPRICORN,
    ta: "மகரம்",
    img: imgCapricorn,
  },
  {
    id: getZodiacId("76109", "1201026", "1130692"),
    en: ZODIAC_AQUARIUS,
    ta: "கும்பம்",
    img: imgAquarius,
  },
  {
    id: getZodiacId("76105", "1201019", "1130720"),
    en: ZODIAC_PISCES,
    ta: "மீனம்",
    img: imgPisces,
  },
];

export const ZODIACS_TA = ZODIACS.map((zodiac) => zodiac.ta);
