import Advertisement from "@components/Elements/Advertisement/Advertisement";
import { GoogleAdsSlotFactory } from "@components/Elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

import { pubPageAdTargeting } from "../AdsConstants";

type AdsImuProps = {
  sectionName?: string;
  rootClassName?: string;
  pageAdTargetType: PageAdTargetValue;
};

/**
 * TM IMU Ads
 * Description: The IMU ads to render required ads.
 * @sectionName - name of section or page
 * @rootClassName - to override the classes
 */

export function AdsImu1({
  sectionName,
  rootClassName,
  pageAdTargetType,
}: AdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <Advertisement
      rootClassName={twMerge(
        "flex justify-center mb-6 lg:min-h-60",
        rootClassName,
      )}
      adUnitProps={{
        type: AdvertisementTypeEnum.IMU1,
        slot: GoogleAdsSlotFactory.imu1(sectionName),
        id: "dfp-ad-imu1",
        pubAdTargeting: [PubPageAdTarget],
      }}
    />
  );
}

export function AdsImu2({
  sectionName,
  rootClassName,
  pageAdTargetType,
}: AdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <Advertisement
      rootClassName={twMerge(
        "flex justify-center mb-6 lg:min-h-60",
        rootClassName,
      )}
      adUnitProps={{
        type: AdvertisementTypeEnum.IMU2,
        slot: GoogleAdsSlotFactory.imu2(sectionName),
        pubAdTargeting: [PubPageAdTarget],
      }}
    />
  );
}

export function AdsMidArticle({
  sectionName,
  rootClassName,
  pageAdTargetType,
}: AdsImuProps) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<PageAdTargetValue>;
  return (
    <Advertisement
      rootClassName={twMerge("flex justify-center", rootClassName)}
      adUnitProps={{
        type: AdvertisementTypeEnum.MIDARTICLESPECIAL,
        slot: GoogleAdsSlotFactory.midarticlespecial(sectionName),
        pubAdTargeting: [PubPageAdTarget],
      }}
    />
  );
}
