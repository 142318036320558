import ChevronImage from "@assets/video_widget/Chevron.webp";
import BackChevronImage from "@assets/video_widget/Right.webp";
import { twMerge } from "tailwind-merge";

export const ToggleButton = ({
  isDisabled,
  onClick,
  isPrevious,
}: {
  isDisabled?: boolean;
  onClick: () => void;
  isPrevious?: boolean;
}) => {
  return (
    <button
      onClick={onClick}
      className={twMerge(
        "h-[38px] w-[38px] bg-[white] cursor-pointer rounded-full shadow-[0px_0px_8px_0px_#00000026]",
        isDisabled && "opacity-[60%]",
        isPrevious && !isDisabled && "rotate-180",
        !isPrevious && isDisabled && "rotate-180",
      )}
    >
      <img
        alt="toggle-button-chevie"
        height={28}
        width={28}
        className={twMerge("inline")}
        src={isDisabled ? BackChevronImage : ChevronImage}
      />
    </button>
  );
};
