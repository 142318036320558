export function increasePagination(pageNumber: string) {
  if (typeof window !== "undefined" && window._data) {
    const _data = window._data as { pagination?: string };
    _data.pagination = pageNumber;
  }
}

type Breakpoint = 320 | 375 | 425 | 768;

interface BreakpointValue {
  breakpoint: Breakpoint;
  value: number;
}

const breakpoints: BreakpointValue[] = [
  { breakpoint: 320, value: 3350 },
  { breakpoint: 375, value: 3150 },
  { breakpoint: 425, value: 2900 },
  { breakpoint: 768, value: 2700 },
];

export function getInitialValue(
  initialScroll: boolean,
  innerWidth: number,
): number {
  let initialValue = initialScroll ? 2100 : 375;

  for (const { breakpoint, value } of breakpoints) {
    if (innerWidth <= breakpoint) {
      initialValue = value;
      break;
    }
  }

  return initialValue;
}
