import { twMerge } from "tailwind-merge";

import Date from "../../Typography/Date";
import PodcastPoster from "./PodcastPoster";
import VideoPoster from "./VideoPoster";

export type ThumbnailProps<T> = T & {
  id?: string;
  description: string;
  name: string;
  published_at: string;
  nowPlaying: boolean;
  thumbnail: string;
  formattedTime?: string | undefined;
  variant: "video" | "podcast";
  handleOnClickThumbnail: (props: T) => void;
  customCardStyles?: string;
  isHideDateTime?: boolean;
  isDark?: boolean;
  videoPosterDimensions?: string;
};

export default function ThumbnailCard<T>(props: ThumbnailProps<T>) {
  const {
    name,
    published_at,
    nowPlaying,
    thumbnail,
    formattedTime,
    variant,
    handleOnClickThumbnail,
    customCardStyles,
    isHideDateTime,
    videoPosterDimensions,
    isDark,
    id,
  } = props;

  const Wrapper = variant === "video" ? "div" : "button";
  const wrapperProps =
    variant === "video"
      ? {}
      : {
          onClick: () => {
            handleOnClickThumbnail(props);
          },
        };

  return (
    <div
      id={`thumbnail-card-${id}`}
      className={twMerge("flex py-2 lg:py-4 gap-4", customCardStyles)}
      data-testid="thumbnail-card"
    >
      <button
        onClick={() => {
          handleOnClickThumbnail(props);
        }}
        className={twMerge(variant === "video" && "inline-flex")}
      >
        {variant === "video" && (
          <VideoPoster
            videoPosterDimensions={videoPosterDimensions}
            nowPlaying={nowPlaying}
            src={thumbnail}
            formattedTime={formattedTime}
          />
        )}
        {variant === "podcast" && <PodcastPoster src={thumbnail} />}
      </button>

      <Wrapper
        {...wrapperProps}
        className="flex flex-col w-full cursor-pointer"
        onClick={() => {
          handleOnClickThumbnail(props);
        }}
      >
        <h4
          className={twMerge(
            "text-[16px] font-secondary text-black-200 leading-xs text-start",
            variant === "podcast" && "font-semibold",
            variant === "video" && isDark ? "text-[#FAFAFA]" : "text-gray-100",
          )}
          data-testid="name"
        >
          {name}
        </h4>
        {isHideDateTime ? null : (
          <div
            className={twMerge(
              "flex flex-col-reverse items-end justify-between w-full lg:items-center lg:flex-row",
              variant === "video" && "items-start",
            )}
          >
            <Date>{published_at}</Date>
          </div>
        )}
      </Wrapper>
    </div>
  );
}
