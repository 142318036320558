import { BrightcoveVideo } from "@components/BrightcoveVideo/BrightcoveVideo";
import ArticleImage from "@components/Carousel/ArticleImage";
import RelatedArticleCarousel from "@components/Carousel/RelatedArticleCarousel";
import { AdsImu1, AdsMidArticle } from "@elements/Advertisement/variant/AdsImu";
import {
  CompanionAdsImu1,
  CompanionAdsMidArticle,
} from "@elements/Advertisement/variant/CompanionAdImu";
import Paragraph from "@elements/Typography/Paragraph";
import { RenderTags } from "@elements/Typography/Tags";
import { getUserType } from "@helper/mySPH";
import { isArrayWithElements } from "@helper/utils";
import { ProcessedArticleData } from "@transformer/useOsResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import {
  type ProcessedArticleBody,
  ProcessedTagsContent,
} from "@typings/Opensearch";
import React, { useEffect, useState } from "react";

import ApplyParagraphAnnotations from "./ApplyParagraphAnnotations";
import { YOUTUBE_WATCH_URL } from "./Embeds/constants";
import Embed from "./Embeds/Embed";
import YouTubeEmbedCustom from "./Embeds/YouTubeEmbedCustom";
import GetLimitedParagraphsFromArticleBody from "./GetLimitedParagraphsFromArticleBody";
import ParagraphList from "./ParagraphElements/ParagraphList";
import PullQuoteCard from "./ParagraphElements/PullQuoteCard";
import SubHead from "./ParagraphElements/SubHead";
import WrapperContainer from "./ParagraphElements/WrapperContainer";
const socialIconList = ["whatsapp", "facebook", "x", "copylink"];
import ShareSocialButton from "@elements/ShareSocial/ShareSocialButton";

export type ArticleParagraphProps = {
  articleBody: ProcessedArticleBody;
  relatedStories: ProcessedArticleData[];
  sectionName: string | undefined;
  tags?: ProcessedTagsContent[];
  pageUrl: string;
  pageNumber: number;
  isInfiniteScrollArticle?: boolean;
};

export default function ArticleParagraph({
  articleBody,
  relatedStories,
  sectionName,
  tags,
  pageUrl,
  pageNumber,
  isInfiniteScrollArticle,
}: ArticleParagraphProps) {
  const [trimmedParagraphs, setTrimmedParagraphs] =
    useState<ProcessedArticleBody>(
      GetLimitedParagraphsFromArticleBody(articleBody, 2),
    );

  const userType = getUserType();
  useEffect(() => {
    setTrimmedParagraphs(articleBody);
  }, [articleBody, userType]);

  let paragraphCount = 0;
  const pageAdTarget = PageAdTargetingTypeEnum.ARTICLE;

  let adsImu1 = (
    <AdsImu1
      pageAdTargetType={pageAdTarget}
      sectionName={sectionName}
      rootClassName={"my-2 border-y border-gray-2200"}
    />
  );
  let adsMidArticle = (
    <AdsMidArticle
      pageAdTargetType={pageAdTarget}
      sectionName={sectionName}
      rootClassName={"my-2 border-y border-gray-2200"}
    />
  );

  if (isInfiniteScrollArticle) {
    adsImu1 = (
      <CompanionAdsImu1
        uniqueSectionName={sectionName}
        pageAdTargetType={pageAdTarget}
        index={pageNumber}
      />
    );
    adsMidArticle = (
      <CompanionAdsMidArticle
        uniqueSectionName={sectionName}
        pageAdTargetType={pageAdTarget}
        index={pageNumber}
      />
    );
  }

  return (
    <div data-testid="article-body">
      {trimmedParagraphs.map((element, index) => {
        let Ads = null;

        if (paragraphCount === 1) {
          Ads = <>{adsImu1}</>;
        } else if (paragraphCount === 5) {
          Ads = <>{adsMidArticle}</>;
        }

        switch (element.type) {
          case "paragraph":
            paragraphCount++;
            return (
              <React.Fragment key={index}>
                <Paragraph testid={`paragraph-${index}`}>
                  {ApplyParagraphAnnotations(
                    element.value,
                    element.annotations,
                  )}
                </Paragraph>
                {Ads}
              </React.Fragment>
            );

          case "sub_head":
            return (
              <React.Fragment key={index}>
                <SubHead element={element} />
              </React.Fragment>
            );

          case "image": {
            const imageHeight =
              element.image?.height && element.image.height >= 1460
                ? 1460
                : 550;
            return (
              <ArticleImage
                key={index}
                url={element.image?.url}
                alt={element.caption}
                caption={element.caption}
                credit={element.credit}
                height={imageHeight}
                className="mb-10 mt-2"
              />
            );
          }

          case "embed":
            return (
              <React.Fragment key={index}>
                <Embed className="my-lg" src={element.embedUrl} />
              </React.Fragment>
            );

          case "pull_quote":
            return (
              <React.Fragment key={index}>
                <PullQuoteCard
                  quote={element.quote}
                  quotee={element.quotee}
                  quoteLabel={element.quoteLabel}
                  quoteCredit={element.quoteCredit}
                />
              </React.Fragment>
            );

          case "list_bulleted":
          case "list_numbered": {
            const listType = element.type === "list_bulleted" ? "ol" : "ul";
            const listItems = element.listItem;
            const listStyle =
              element.listStyle === "decimal" ? "list-decimal" : "list-disc";
            return (
              <React.Fragment key={index}>
                <ParagraphList
                  listStyle={listStyle}
                  listType={listType}
                  listItems={listItems}
                />
              </React.Fragment>
            );
          }

          case "youtube_video": {
            const url = `${YOUTUBE_WATCH_URL}${element.youtubeId}`;
            return (
              <React.Fragment key={index}>
                <YouTubeEmbedCustom url={url} className="my-lg" />
              </React.Fragment>
            );
          }

          case "brightcove_video": {
            return (
              <React.Fragment key={index}>
                <WrapperContainer className="block my-lg">
                  <BrightcoveVideo videoId={element.videoId} />
                </WrapperContainer>
              </React.Fragment>
            );
          }

          default:
            return null;
        }
      })}

      <ShareSocialButton
        socialIconList={socialIconList}
        pagePath={pageUrl}
        mbClassName="bg-white-100 z-10 rounded-3xl mb-5 mr-sm"
        deskClassName="mb-5"
      />

      {/* Tags dont dont render If alias not available in payload*/}
      {isArrayWithElements(tags) && (
        <RenderTags tagsWrapper="mb-6" tags={tags} />
      )}

      {isArrayWithElements(relatedStories) && (
        <RelatedArticleCarousel
          relatedArticles={relatedStories}
          rootClassName="mt-6 overflow-hidden"
          testId="related-article-carousel"
        />
      )}
    </div>
  );
}
