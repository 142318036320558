/* eslint-disable react-hooks/rules-of-hooks */
import MainBlock from "@container/MainBlock";
import MainContainer from "@container/MainContainer";
import SideBlock from "@container/SideBlock";
import WrapperBlock from "@container/WrapperBlock";
import { NEWSLETTER_COOKIE_MOBILE } from "@src/app/components/Blocks/NewsletterSignupForm/constants";
import NewsletterSignupForm from "@src/app/components/Blocks/NewsletterSignupForm/NewsletterSignupForm";
import { PageAdTargetingTypeEnum } from "@src/app/types/Ads.d";
import Cookies from "js-cookie";
import { useSearchParams } from "react-router-dom";

import { NewsletterMetaData } from "./NewsletterMetaData";

export default function Newsletter() {
  const [searchParams] = useSearchParams();
  const mobwebview = searchParams.get("mobwebview");

  if (mobwebview && mobwebview == "true") {
    Cookies.set(NEWSLETTER_COOKIE_MOBILE, "true", {
      path: "/",
      SameSite: "None",
      Secure: true,
    });
  } else {
    Cookies.set(NEWSLETTER_COOKIE_MOBILE, "false", {
      path: "/",
      expires: new Date(0), // Thu, 01 Jan 1970 00:00:00 UTC
    });
  }

  const pageAdTargetValue = PageAdTargetingTypeEnum.LISTING;

  const mobwebviewCookie = Cookies.get(NEWSLETTER_COOKIE_MOBILE);
  const mobwebviewBool = mobwebviewCookie == "true";

  const sectionName = "newsletter-signup";

  const { gaData, metaTags } = NewsletterMetaData({
    sectionName,
  });

  return (
    <MainContainer
      gaData={gaData}
      metaTagsProps={metaTags}
      sectionName={sectionName}
      mobwebview={mobwebviewBool}
      pageAdTargetValue={pageAdTargetValue}
    >
      <WrapperBlock className="flex flex-col lg:flex-row justify-between">
        <MainBlock pageName={sectionName} showTitle={false}>
          <div className="flex flex-wrap">
            <h5 className="text-md font-bold leading-600 mb-2">
              எங்களுடன் இணைந்திருங்கள்!
            </h5>
            <NewsletterSignupForm />
          </div>
        </MainBlock>
        {!mobwebviewBool && (
          <SideBlock
            sectionName={sectionName}
            className="w-full lg:block"
            pageAdTargetValue={pageAdTargetValue}
          />
        )}
      </WrapperBlock>
    </MainContainer>
  );
}
