/**
 * Company: SPHMedia
 * Description: This is a List Item component
 */

import { CSSProperties, ReactElement } from "react";

type ListItemProps = {
  /** This is a testId prop for the list item */
  testId?: string; // Define the type for the props required by the component (if any)
  /** This is a style prop for the list item */
  style?: CSSProperties;
  className?: string;
  /** This is a children of the list item */
  children: ReactElement;
};

/**
 * ListItem
 * Description: This is a List Item component
 * @param props - The props required by the component
 * @returns The ListItem component
 */
export function ListItem({
  children,
  style,
  testId,
  className,
}: ListItemProps): ReactElement {
  return (
    <li data-testid={testId} style={style} className={className}>
      {children}
    </li>
  );
}

export type ListItemType = ReactElement<ListItemProps>;
