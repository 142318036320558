import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export type dateProps = {
  children?: ReactNode;
  className?: string;
};

export default function Date({ children, className }: dateProps) {
  return (
    <p
      className={twMerge(
        "font-primary font-medium text-gray-800 text-xs",
        className,
      )}
    >
      {children}
    </p>
  );
}
