import { getCookieByName } from "@components/helper/utils";
import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";

export type GADataProps = {
  level2: string;
  chapter1?: string;
  at?: string;
  visitorcat?: 1 | 2 | undefined;
  title?: string;
  articleid?: string;
  author?: string;
  contentcat: 1 | 2 | 3;
  contenttype?: 1 | 3 | "horoscope" | undefined;
  pubdate?: string;
  keyword?: string;
  pagination?: number;
  abVariant?: string;
  cd_version?: "tm2" | undefined;
  infinite_scroll?: "0" | "1";
  cue_articleid?: string;
  content_publication_date_utc?: string;
  content_update_date_utc?: string;
};

/**
 * GAData
 * Description: This component renders the _data for GA to consume.
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GAData(props: GADataProps): ReactElement {
  const {
    level2 = "",
    chapter1 = "", // Section category name.
    at = getCookieByName("mysphw") ?? "", // mysphw cookie
    visitorcat = getCookieByName("visitorcat") ?? 1,
    title = "", // can contain spaces
    articleid = "",
    author = "", // Comma separated author name
    contentcat = 1, // Paid (2) or free (1)
    contenttype = "", // index/listing, article
    pubdate = "", // Post date of article otherwise ""
    keyword = "", // Comma separated keyword names
    pagination = 1,
    cd_version = "tm2",
    cue_articleid = "",
    content_publication_date_utc = "",
    content_update_date_utc = "",
  } = props;

  return (
    <Helmet>
      <script id="ga_data_layer">
        {`
          var gtmdataLayer = {};
          gtmdataLayer = {
            "level2"                       : "${level2}",
            "chapter1"                     : "${chapter1}",
            "at"                           : "${at}",
            "visitorcat"                   : ${visitorcat},
            "title"                        : "${title}",
            "articleid"                    : "${articleid}",
            "author"                       : "${author}",
            "contentcat"                   : "${contentcat}",
            "contenttype"                  : "${contenttype}",
            "pubdate"                      : "${pubdate}",
            "keyword"                      : "${keyword}",
            "pagination"                   : "${pagination}",
            "cd_version"                   : "${cd_version}",
            "cue_articleid"                : "${cue_articleid}",
            "content_publication_date_utc" : "${content_publication_date_utc}",
            "content_update_date_utc"      : "${content_update_date_utc}",
          };
          window.dataLayer = window.dataLayer || [];
          if (typeof window.getCookieByName === 'function') {
            if(window.getCookieByName("visitorcat") != undefined && window.getCookieByName("mysphw") != undefined) {
              var visitorcat = window.getCookieByName("visitorcat");
              gtmdataLayer.visitorcat = visitorcat;
              gtmdataLayer.at = window.getCookieByName("mysphw");
            }
          }
          window.dataLayer.push(gtmdataLayer);
     
        `}
      </script>
    </Helmet>
  );
}
