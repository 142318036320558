import { twMerge } from "tailwind-merge";

import { Image } from "../Elements/ResponsiveImage/Image";
import ArticleCarouselFooter from "./ArticleCarouselFooter";

type ArticleImageProps = {
  url?: string;
  width?: number;
  height?: number;
  sectionName?: string;
  caption: string;
  credit: string;
  alt: string;
  index?: string;
  className?: string;
  currentSlideCount?: number;
  totalSlideCount?: number;
};

export default function ArticleImage({
  alt,
  url,
  width = 825,
  height = 550,
  caption,
  credit,
  index,
  className,
  currentSlideCount,
  totalSlideCount,
}: ArticleImageProps) {
  return (
    <div className={twMerge("max-w-[825px] mb-4", className)} key={index}>
      <div className="w-full">
        <Image
          src={url ?? ""}
          displayWidth={width}
          srcWidth={width}
          srcHeight={height}
          alt={alt}
        />
        <ArticleCarouselFooter
          caption={caption}
          credit={credit}
          currentSlideCount={currentSlideCount}
          totalSlideCount={totalSlideCount}
        />
      </div>
    </div>
  );
}
