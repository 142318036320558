import type { TRouteWithoutRedirect } from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import fetchCaas from "@src/app/components/helper/fetchCaas";
import fetchTMApi from "@src/app/components/helper/fetchTMApi";
import { UrlMatch } from "@src/app/data";
import { getOsBySection } from "@src/app/query/query";
import { RouteParam, SectionRouteParam } from "@src/app/types/Data";
import { MoreArticlesRequestData } from "@src/app/types/MoreArticlesApi";
import { OSResponse, PartialCueArticle } from "@src/app/types/Opensearch";
import getOSProcessedArticleData, {
  type ProcessedArticleData,
} from "@transformer/useOsResponse";
import { Match, MatchResult } from "path-to-regexp";

export const isSectionMatchResult = (
  a: Match,
): a is MatchResult<SectionRouteParam> => {
  return "level1" in ((a as MatchResult).params as RouteParam);
};

export const fetchSectionData = async ({
  urlComponent,
}: UrlMatch): Promise<
  TRouteWithoutRedirect<ProcessedArticleData[], string>
> => {
  const section = isSectionMatchResult(urlComponent)
    ? urlComponent.params.level1
    : "";

  try {
    const SectionList = (await fetchCaas({
      searchQuery: getOsBySection(section, 5),
    })) as OSResponse<PartialCueArticle>;

    const hits = SectionList.payload.hits.total.value;
    if (hits === 0) {
      console.error("Section page error 404", SectionList);
      return { type: ResponseType.CLIENT_ERROR, statusCode: 404 };
    }

    return {
      type: ResponseType.SUCCESS,
      statusCode: 200,
      payload: getOSProcessedArticleData(SectionList),
    };
  } catch (e) {
    const error = e as Error;
    console.error("Section page error 500", error);
    return {
      type: ResponseType.SERVER_ERROR,
      statusCode: 500,
      payload: error.message,
    };
  }
};

export async function fetchMoreStoriesData(
  requestData: MoreArticlesRequestData,
): Promise<ProcessedArticleData[] | []> {
  try {
    const response: ProcessedArticleData[] = await fetchTMApi(
      "more-section-stories",
      "POST",
      {
        section: requestData.filterArticlesBasedOn,
        sort: requestData.sort,
        size: 5,
      },
    );

    return response;
  } catch (error) {
    return [];
  }
}
